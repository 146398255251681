import BannerLogo from "../assets/Images/BannerWeb.png";
import BannerLogoOfMobile from "../assets/Images/Frame47402.png";
import styled from "styled-components";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
export type BannerProps = {
  isTabletOrMobile: boolean;
};
const BigBanner = styled.div<BannerProps>`
  background: ${(props) =>
    props.isTabletOrMobile
      ? `url(${BannerLogoOfMobile}) no-repeat center;`
      : `url(${BannerLogo}) no-repeat center;`}
    ${(props) =>
      props.isTabletOrMobile ? `padding-top: 290px;` : `padding-top: 20%;`}
    background-size: cover;
`;
const Banner = () => {
  const isTabletOrMobile = useMediaQuery("(max-width: 900px)");
  const { t } = useTranslation();

  return (
    <div>
      <BigBanner
        style={{ position: "relative" }}
        isTabletOrMobile={isTabletOrMobile}
      >
        {!isTabletOrMobile && (
          <div
            style={{ position: "absolute", top: "20%", margin: "0px 166px" }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: ["48px", "64px", "64px", "80px", "80px", "80px"],
                lineHeight: "100px",
                mb: ["5px", "5px", "5px", "5px", "10px", "10px"],
                fontFamily: "San Francisco Text",
              }}
            >
              {t("title.main")}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: ["12px", "12px", "16px", "16px", "20px", "20px"],
                lineHeight: "28px",
                fontFamily: "San Francisco Text",
              }}
            >
              {t("title.description")}
            </Typography>
          </div>
        )}
      </BigBanner>
    </div>
  );
};

export default Banner;
