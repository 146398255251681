const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.5384 6.11399C13.5384 8.00723 11.965 9.56136 9.99996 9.56136C8.03491 9.56136 6.4615 8.00723 6.4615 6.11399C6.4615 4.22076 8.03491 2.66663 9.99996 2.66663C11.965 2.66663 13.5384 4.22076 13.5384 6.11399ZM5.83196 12.5823C6.57871 11.8524 7.59353 11.4398 8.65439 11.4386H11.3455C12.4064 11.4398 13.4212 11.8524 14.168 12.5823C14.9143 13.3118 15.332 14.2985 15.3333 15.3251V17.9561C15.3333 18.0524 15.2943 18.1471 15.2209 18.2188C15.1472 18.2908 15.0449 18.3333 14.9359 18.3333H5.06406C4.95502 18.3333 4.85268 18.2908 4.77898 18.2188C4.70566 18.1471 4.66663 18.0524 4.66663 17.9561V15.3248C4.66797 14.2983 5.08567 13.3117 5.83196 12.5823Z"
        fill="#6B7380"
        stroke="#6B7380"
      />
    </svg>
  );
};

export default UserIcon;
