import { createContext, Dispatch, SetStateAction } from "react";

export interface Language {
  language: string;
}

interface LanguageContextProps {
  language: Language | null;
  setLanguage: Dispatch<SetStateAction<Language | null>>;
}

const LanguageContext = createContext<LanguageContextProps>({
  language: null,
  setLanguage: () => {},
});

export default LanguageContext;
