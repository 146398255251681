interface icon {
  color: string;
}
export default function IconAI({ color }: icon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.025 5.62298C3.093 5.82698 3.285 5.96498 3.5 5.96498C3.715 5.96498 3.906 5.82698 3.975 5.62298L4.396 4.35998L5.659 3.93898C5.863 3.87098 6.001 3.67998 6.001 3.46498C6.001 3.24998 5.863 3.05898 5.659 2.99098L4.396 2.56998L3.975 1.30698C3.838 0.898977 3.163 0.898977 3.026 1.30698L2.605 2.56998L1.342 2.99098C1.138 3.05898 1 3.24998 1 3.46498C1 3.67998 1.138 3.87098 1.342 3.93898L2.605 4.35998L3.025 5.62298Z"
        fill={color}
      />
      <path
        d="M16.525 8.8029L11.99 7.0099L10.197 2.4749C9.97002 1.9029 9.02902 1.9029 8.80202 2.4749L7.00902 7.0099L2.47402 8.8029C2.18802 8.9159 1.99902 9.1929 1.99902 9.4999C1.99902 9.8069 2.18702 10.0839 2.47402 10.1969L7.00902 11.9899L8.80202 16.5249C8.91502 16.8109 9.19202 16.9989 9.49902 16.9989C9.80602 16.9989 10.083 16.8109 10.196 16.5249L11.989 11.9899L16.524 10.1969C16.81 10.0839 16.999 9.8069 16.999 9.4999C16.999 9.1929 16.812 8.9159 16.525 8.8029Z"
        fill={color}
      />
    </svg>
  );
}
