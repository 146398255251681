import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { SelectOption } from "../MainBody/Form";
import { LabelForAuto } from "./StyledComponents";
import { forwardRef, useEffect, useRef } from "react";
import { Tooltip, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./styles.css";

const BootstrapInputForSelectField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "5px",
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#E1E2EB",
      border:
        theme.palette.mode === "dark"
          ? "1px solid #4B5563"
          : "1px solid #D1D5DB",
    },
    fontFamily: "San Francisco Text",
    "&.Mui-focused fieldset": {
      boxShadow: `rgb(25 118 210 / 25%) 0 0 0 0.2rem`,
      border: `1.5px solid #1976d2`,
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#e5e7e9",
  },
}));

interface Option {
  id: string;
  label: string;
  fuel?: string;
}

const AutoCompleteInputWithConditionalTextField = forwardRef(
  (
    props: {
      files?: any;
      label: string;
      listOption: SelectOption[];
      onChange?: Function;
      value: SelectOption | undefined;
      disabled?: boolean;
      placeholder?: string;
      open?: boolean;
      onClose?: Function;
      onOpen?: Function;
      setFiles?: Function;
      setDataFromImage?: Function;
      otherValue: string;
      onChangeOtherValue: Function;
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const theme = useTheme();

    useEffect(() => {
      if (props.open && inputRef.current) {
        inputRef.current.focus();
      }
    }, [props.open]);

    const getOptionLabel = (option: Option) => {
      if (option.id !== "" && option.fuel) {
        return option.label + ` (${option.fuel})`;
      }
      if (option.label === "LRTN" && parseInt(option.id) === 2) {
        return "Lắp ráp trong nước";
      }
      if (option.label === "NK" && parseInt(option.id) === 1) {
        return "Nhập khẩu";
      }
      return option.label;
    };

    return (
      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
        <LabelForAuto
          style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}
        >
          {props.label}
        </LabelForAuto>
        <Box
          sx={{
            border:
              props.value && props.value?.id === "other"
                ? theme.palette.mode === "light"
                  ? "1px solid #D1D5DB"
                  : "1px solid #4B5563"
                : "none",
            p: props.value && props.value?.id === "other" ? 2 : 0,
            borderRadius: "8px",
            bgcolor:
              props.value && props.value?.id === "other"
                ? theme.palette.mode === "light"
                  ? "#F9FAFB"
                  : "#2F353C"
                : theme.palette.mode === "light"
                ? "white"
                : "#2F353C",
          }}
        >
          <Tooltip
            title={props.disabled ? "" : props.value?.label}
            followCursor
          >
            <Autocomplete
              value={props.value}
              disablePortal
              options={props.listOption}
              getOptionLabel={getOptionLabel}
              sx={{ width: "100%" }}
              onChange={(_, newValue) => {
                _.stopPropagation();
                if (props.onChange) {
                  props.onChange(newValue);
                  if (props.files) {
                    props.setFiles && props.setFiles([]);
                  }
                }
              }}
              disabled={props.disabled}
              renderInput={(params) => (
                <BootstrapInputForSelectField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border:
                          props.value && props.value?.id === "other"
                            ? "none"
                            : "1px solid #D1D5DB",
                        // backgroundColor:
                        //   props.value && props.value?.id !== "other"
                        //     ? theme.palette.mode === "light"
                        //       ? "#F3F4F6"
                        //       : "#4B5563"
                        //     : "none",
                      },
                    },
                  }}
                  {...params}
                  placeholder={props.placeholder}
                  size="small"
                  inputRef={(el) => {
                    inputRef.current = el;
                    if (ref) {
                      if (typeof ref === "function") {
                        ref(el);
                      } else {
                        ref.current = el;
                      }
                    }
                  }}
                />
              )}
              open={props.open}
              onClose={() => {
                if (props.onClose) {
                  props.onClose();
                }
              }}
              onOpen={() => {
                if (props.onOpen) {
                  props.onOpen();
                }
              }}
            />
          </Tooltip>
          {props.value && props.value?.id === "other" && (
            <input
              placeholder="Nhập số loại"
              value={props.otherValue}
              onChange={(e: any) => props.onChangeOtherValue(e.target.value)}
              style={{
                border: "none",
                width: "100%",
                height: "40px",
                padding: "12px",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#9CA3AF",
                backgroundColor:
                  theme.palette.mode === "light" ? "white" : "#4B5563",
              }}
            />
          )}
        </Box>
      </FormControl>
    );
  }
);

export default AutoCompleteInputWithConditionalTextField;
