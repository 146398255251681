import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
// import { SelectOption } from "../MainBody/Form";
import { LabelForAuto } from "./StyledComponents";
import { useTheme } from "@mui/material/styles";

const BootstrapInputForSelectField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "5px",
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#E1E2EB",
      border:
        theme.palette.mode === "dark"
          ? "1px solid #4B5563"
          : "1px solid #D1D5DB",
    },
    fontFamily: "San Francisco Text",
    "&.Mui-focused fieldset": {
      boxShadow: `rgb(25 118 210 / 25%) 0 0 0 0.2rem`,
      border: `1.5px solid #1976d2`,
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.mode === "light" ? "#e5e7e9" : "unset",
  },
}));

const TextFieldInput = ({
  label,
  onChange,
  value,
  disabled,
  placeholder,
  type,
}: {
  label: string;
  onChange?: Function;
  value: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}) => {
  const theme = useTheme();
  return (
    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
      <LabelForAuto
        style={{ color: theme.palette.mode === "dark" ? "#FFF" : "#111" }}
      >
        {label}
      </LabelForAuto>
      <BootstrapInputForSelectField
        value={value}
        placeholder={placeholder}
        size="small"
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        disabled={disabled}
        type={type}
        // inputRef={(ref) => {
        //   if (ref) {
        //     ref.focus();
        //   }
        // }}
      />
    </FormControl>
  );
};

export default TextFieldInput;
