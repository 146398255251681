export default function IconInformation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.6665 1.33301H6.33317C4.3105 1.33301 2.6665 2.97701 2.6665 4.99967V18.9997C2.6665 21.0223 4.3105 22.6663 6.33317 22.6663H17.6665C19.6892 22.6663 21.3332 21.0223 21.3332 18.9997V4.99967C21.3332 2.97701 19.6892 1.33301 17.6665 1.33301ZM6.6665 6.33301C6.6665 5.78101 7.1145 5.33301 7.6665 5.33301H9.6665C10.2185 5.33301 10.6665 5.78101 10.6665 6.33301V8.33301C10.6665 8.88501 10.2185 9.33301 9.6665 9.33301H7.6665C7.1145 9.33301 6.6665 8.88501 6.6665 8.33301V6.33301ZM16.3332 17.333H7.6665C7.1145 17.333 6.6665 16.885 6.6665 16.333C6.6665 15.781 7.1145 15.333 7.6665 15.333H16.3332C16.8852 15.333 17.3332 15.781 17.3332 16.333C17.3332 16.885 16.8852 17.333 16.3332 17.333ZM16.3332 13.333H7.6665C7.1145 13.333 6.6665 12.885 6.6665 12.333C6.6665 11.781 7.1145 11.333 7.6665 11.333H16.3332C16.8852 11.333 17.3332 11.781 17.3332 12.333C17.3332 12.885 16.8852 13.333 16.3332 13.333ZM16.3332 9.33301H13.6665C13.1145 9.33301 12.6665 8.88501 12.6665 8.33301C12.6665 7.78101 13.1145 7.33301 13.6665 7.33301H16.3332C16.8852 7.33301 17.3332 7.78101 17.3332 8.33301C17.3332 8.88501 16.8852 9.33301 16.3332 9.33301Z"
        fill="white"
      />
    </svg>
  );
}
