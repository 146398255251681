export function getDataFromLocalStorage() {
  const type = localStorage.getItem("type");
  const dataStringify =
    type === "car"
      ? localStorage.getItem("car")
      : type === "truck"
      ? localStorage.getItem("truck")
      : type === "coach"
      ? localStorage.getItem("coach")
      : localStorage.getItem("specialized");
  let data = null;
  if (dataStringify) {
    data = JSON.parse(dataStringify);
  }
  return dataStringify ? data : dataStringify;
}

export function passParamIntoCarValue(
  company: { id: string; label: string },
  model: { id: string; label: string },
  year: { id: string; label: string },
  version: { id: string; label: string },
  option: { id: string; label: string; key?: string },
  origin: { id: string; label: string },
  kmTravel: string
) {
  if (option.id !== "" && origin.id !== "" && kmTravel !== "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      originId: origin.id,
      kmTraveled: kmTravel,
      optionKey: option.key,
    };
  } else if (option.id !== "" && origin.id !== "" && kmTravel === "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      originId: origin.id,
      optionKey: option.key,
    };
  } else if (option.id !== "" && origin.id === "" && kmTravel !== "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      kmTraveled: kmTravel,
      optionKey: option.key,
    };
  } else if (option.id === "" && origin.id !== "" && kmTravel !== "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      originId: origin.id,
      kmTraveled: kmTravel,
    };
  } else if (option.id !== "" && origin.id === "" && kmTravel === "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      optionKey: option.key,
    };
  } else if (option.id === "" && origin.id !== "" && kmTravel === "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      originId: origin.id,
    };
  } else if (option.id === "" && origin.id === "" && kmTravel !== "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
      kmTraveled: kmTravel,
    };
  } else if (option.id === "" && origin.id === "" && kmTravel === "") {
    return {
      carCompany: company.label,
      carModel: model.label,
      carVersion: version.label,
      year: parseInt(year.id, 10),
    };
  }
}

export function passParamIntoCarValueLocal(
  option: any,
  origin: any,
  kmTravel: string,
  data: any
) {
  if (option.id && origin.id !== "" && kmTravel !== "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      originId: data.originId.id,
      kmTraveled: parseInt(data.kmTraveled, 10),
      optionKey: data.optionKey.key,
    };
  } else if (option.id && origin.id !== "" && kmTravel === "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      originId: data.originId.id,
      optionKey: data.optionKey.key,
    };
  } else if (option.id && origin.id === "" && kmTravel !== "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      kmTraveled: parseInt(data.kmTraveled, 10),
      optionKey: data.optionKey.key,
    };
  } else if (option.id === "" && origin.id !== "" && kmTravel !== "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      originId: data.originId.id,
      kmTraveled: parseInt(data.kmTraveled, 10),
    };
  } else if (option.id !== "" && origin.id === "" && kmTravel === "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      optionKey: data.optionKey.key,
    };
  } else if (option.id === "" && origin.id !== "" && kmTravel === "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      originId: data.originId.id,
    };
  } else if (option.id === "" && origin.id === "" && kmTravel !== "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
      kmTraveled: parseInt(data.kmTraveled, 10),
    };
  } else if (option.id === "" && origin.id === "" && kmTravel === "") {
    return {
      carCompany: data.company.label,
      carModel: data.model.label,
      carVersion: data.version.label,
      year: parseInt(data.manufactureyear.id, 10),
    };
  }
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function getCurrentTime() {
  const month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const day = new Date();
  return day.getFullYear() + "-" + month[day.getMonth()];
}

export function checkFirstRecord(
  item: { dateTime: string; price: number },
  array: { dateTime: string; price: number }[]
) {
  if (item.dateTime === array[0].dateTime) {
    return true;
  }
  return false;
}

export function subtraction(
  item: { dateTime: string; price: number },
  array: { dateTime: string; price: number }[]
) {
  const isSelected = (element: { dateTime: string; price: number }) =>
    element.dateTime === item.dateTime;
  const index = array.findIndex(isSelected) - 1;

  return { sub: item.price - array[index].price, index: index };
}

export function checkIsModelCode(
  code: string,
  listModelCode: { id: string; label: string }[]
) {
  for (let i = 0; i < listModelCode.length; i++) {
    if (code === listModelCode[i].id && code !== "other") {
      return i;
    }
  }
  return -1;
}

export function isValidNumericString(str: string) {
  // Kiểm tra nếu chuỗi trống
  if (str === "") return false;

  // Sử dụng hàm parseFloat để kiểm tra xem chuỗi có phải là số hợp lệ không
  const num = parseFloat(str);

  // parseFloat sẽ trả về NaN nếu không phải số hợp lệ
  if (isNaN(num)) return false;

  // Kiểm tra nếu chuỗi đầu vào chứa các ký tự không hợp lệ (ngoài số và dấu chấm)
  // Đồng thời, đảm bảo chuỗi này chỉ có một dấu chấm
  const regex = /^[0-9]+(\.[0-9]+)?$/;

  return regex.test(str);
}

export function clearLocalStorage() {
  localStorage.removeItem("car");
  localStorage.removeItem("truck");
  localStorage.removeItem("coach");
  localStorage.removeItem("specialized");
  localStorage.removeItem("type");
}
