import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectOption } from "../MainBody/Form";
import { useTheme } from "@mui/material/styles";
const BootstrapInputForSelectField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#F9FAFB",
    border:
      theme.palette.mode === "dark" ? "1px solid #4B5563" : "1px solid #D1D5DB",
    fontSize: 16,
    padding: "8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: "8px",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .Mui-disabled": {
    backgroundColor: "#ced4da",
  },
}));

const SelectedInput = ({
  label,
  defaultValue,
  idForInput,
  listOption,
  onChange,
  value,
  disabled,
}: {
  label: string;
  defaultValue?: string;
  idForInput: string;
  listOption: SelectOption[];
  onChange?: Function;
  value: string | undefined;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const handleChange = (event: { target: { value: string } }) => {
    if (onChange) {
      onChange(event.target.value);
      localStorage.setItem("type", event.target.value);
    }
  };
  return (
    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
      <InputLabel
        shrink
        htmlFor={idForInput}
        sx={{
          color: theme.palette.mode === "dark" ? "white" : "black",
          fontSize: "20px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        id={idForInput}
        value={value}
        onChange={handleChange}
        input={<BootstrapInputForSelectField />}
        // IconComponent={ExpandMoreIcon}
        disabled={disabled}
      >
        {listOption.map((list) => (
          <MenuItem key={list.id} value={list.id}>
            {list.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectedInput;
