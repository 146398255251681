//@ts-nocheck
import LoginPage from "Components/Login";
import EvaluatePage from "Components/MainBody";
import SearchPage from "Components/MainBodyV2";
import ResultPage from "Components/Result";
import Cookies from "js-cookie";
import { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserContext, { User } from "userContext";
import LanguageContext, { Language } from "languageContext";
import ColorModeContext from "ColorModeContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import themeTypo from "Common/themeTypo";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [language, setLanguage] = useState<Language | null>(null);
  const [mode, setMode] = useState<string>("light");
  const [loading, setLoading] = useState(true);

  const { isNewInterface } = window._env_;

  useEffect(() => {
    const storedUser = Cookies.get("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const storedLanguage = Cookies.get("language");
    if (storedLanguage) {
      setLanguage(JSON.parse(storedLanguage));
    }

    const mode = localStorage.getItem("mode");
    if (mode) {
      setMode(mode);
    }

    setLoading(false);
  }, []);

  const themeMode = useMemo(() => {
    return createTheme({
      palette: {
        mode: mode === "dark" ? "dark" : "light",
      },
      ...themeTypo,
    });
  }, [mode]);

  useEffect(() => {
    if (user) {
      Cookies.set("user", JSON.stringify(user), { expires: 7 });
      Cookies.set("language", JSON.stringify(language), { expires: 7 });
    } else {
      Cookies.remove("user");
    }
  }, [language, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={{ mode, setMode }}>
        <ThemeProvider theme={themeMode}>
          <LanguageContext.Provider value={{ language, setLanguage }}>
            <UserContext.Provider value={{ user, setUser }}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/"
                  element={!isNewInterface ? <EvaluatePage /> : <SearchPage />}
                />
                <Route path="/result" element={<ResultPage />} />
              </Routes>
            </UserContext.Provider>
          </LanguageContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
}

export default App;
