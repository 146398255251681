// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "userContext";
import Logo from "../assets/Images/Logo.png";
import { stringAvatar } from "../Common/common";
import englandFlag from "../assets/Images/flags/englandFlag.png";
import vietnamFlag from "../assets/Images/flags/vietnamFlag.png";
import { useTranslation } from "react-i18next";
import LanguageContext from "languageContext";
import ColorModeContext from "ColorModeContext";
import { clearLocalStorage } from "../Common/common";

const jsondata = window._env_;

const Navbar = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const { setMode } = useContext(ColorModeContext);
  const { t, i18n } = useTranslation();

  const handleLogout = () => {
    const { beAdminUrl } = jsondata;
    const logout = async () => {
      await fetch(`${beAdminUrl}/logout`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("mode");
          setMode("light");
          setUser(null);
          clearLocalStorage();
          navigate("/login");
          return;
        }
      });
    };
    logout();
  };

  const handleChangeLanguage = (language: string) => {
    setLanguage({ language });
    i18n.changeLanguage(language);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 20px",
        backgroundColor: "#fff",
      }}
    >
      <img width={140} height={32} src={Logo} alt="Logo of AiCycle" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <FormControl>
          <Select
            defaultValue={language?.language || "vi"}
            sx={{
              mr: 2,
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              ".MuiSelect-icon": { display: "none" },
            }}
            variant="standard"
            disableUnderline
            onChange={(e) => handleChangeLanguage(e.target.value as string)}
          >
            <MenuItem value="vi">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={vietnamFlag}
                  alt="Vietnamese flag"
                  width={25.6}
                  height={17.9}
                />
                <Typography
                  sx={{
                    color: "#091E42",
                    fontFamily: "San Francisco Text",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  VI
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="en">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={englandFlag}
                  alt="England flag"
                  width={25.6}
                  height={17.9}
                />
                <Typography
                  sx={{
                    color: "#091E42",
                    fontFamily: "San Francisco Text",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  EN
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />
        <Typography
          sx={{
            color: "#091E42",
            fontFamily: "San Francisco Text",
            fontSize: "14px",
          }}
        >
          {user?.firstName + " " + user?.lastName}
        </Typography>
        <Tooltip title={t("auth.logout.title")} placement="bottom">
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              "&:hover": {
                backgroundColor: "transparent",
                opacity: 1,
              },
            }}
            onClick={handleLogout}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

export default Navbar;
