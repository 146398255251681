/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Grid, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AutoCompleteInput from "Components/FormComponents/AutoCompleteInput";
import AutoCompleteInputWithConditionalTextField from "Components/FormComponents/AutocompleteWithConditionalTextField";
import TextFieldInput from "Components/FormComponents/TextFieldInput";
import NumberInput from "Components/FormComponents/NumberInput";
import { getDataFromLocalStorage } from "Common/common";
import {
  getCarInfoCompanyByType,
  getCarModelCodeByType,
  getCarInfoModelByType,
  getPriceV3,
} from "../../../MainBody/api";
import {
  SelectOption,
  CarTypeCompanyInfo,
  CarModelCodeType,
  CarInfoModelType,
} from "./type";
import {
  ResultCarPrice,
  onRoadPriceResult,
  CarPriceInflation,
  carEstimatePrice,
} from "../../types";
import { listOptionManufactureYear } from "Components/MainBodyV2/CarType/listOption";
import { checkIsModelCode } from "Common/common";

const SpecializedVehicleForm = ({
  setCarPriceValue,
  setOnRoadPrice,
  setPriceVolatility,
  setCarEstimatedPrice,
  setIsLoadCarPrice,
  setError,
  setErrorVolatility,
  setErrorEstimated,
  onValueChange,
}: {
  setCarPriceValue: React.Dispatch<React.SetStateAction<ResultCarPrice>>;
  setOnRoadPrice: React.Dispatch<React.SetStateAction<onRoadPriceResult>>;
  setPriceVolatility: React.Dispatch<React.SetStateAction<CarPriceInflation[]>>;
  setCarEstimatedPrice: React.Dispatch<React.SetStateAction<carEstimatePrice>>;
  setIsLoadCarPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorVolatility: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorEstimated: React.Dispatch<React.SetStateAction<boolean>>;
  onValueChange: (value: string) => void;
}) => {
  const isTabletOrMobile = useMediaQuery("(max-width: 1050px)");
  const { t } = useTranslation();
  const theme = useTheme();

  const [openCarModel, setOpenCarModel] = useState<boolean>(false);
  const [openModelCode, setOpenModelCode] = useState<boolean>(false);

  const [selectedCarCompany, setSelectedCarCompany] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedModel, setSelectedModel] = useState<SelectOption>({
    id: "",
    label: "",
  });

  const [selectedModelCode, setSelectedModelCode] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedManufactured, setSelectedManufactured] =
    useState<SelectOption>({ id: "", label: "" });
  const [weight, setWeight] = useState<string>("");
  const [specializedVehicle, setSpecializedVehicle] = useState<string>("");
  const [otherValue, setOtherValue] = useState<string>("");

  const [listCarCompany, setListCarCompany] = useState<SelectOption[]>([]);
  const [listCarInfoModel, setListCarInfoModel] = useState<SelectOption[]>([]);
  const [listModelCode, setListModelCode] = useState<SelectOption[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoadDataFrLocal, setIsLoadDataFrLocal] = useState<boolean>(true);

  const listHideCarBrand: string[] = useMemo(
    () => [
      "ed70991f-411c-4ac0-8ae4-0c7d66427e59",
      "06df3ccf-b96f-4c95-98fb-801b25295a66",
      "b7b77302-86a5-4d13-a621-d25bb9c48569",
      "f31e885d-dfc5-403c-beb2-da3d0a20c9ce",
      "20d8df48-232c-4711-9eac-b6fbf5d67253",
    ],
    []
  );

  const data = getDataFromLocalStorage();

  useEffect(() => {
    if (otherValue !== "") {
      if (checkIsModelCode(otherValue, listModelCode) !== -1) {
        setSelectedModelCode(
          listModelCode[checkIsModelCode(otherValue, listModelCode)]
        );
      }
    }
  }, [otherValue]);

  useEffect(() => {
    if (
      selectedModelCode &&
      selectedModelCode.id === "other" &&
      !isLoadDataFrLocal
    ) {
      setWeight("");
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
    } else if (
      selectedModelCode &&
      selectedModelCode.id !== "other" &&
      !isLoadDataFrLocal
    ) {
      setOtherValue("");
      setWeight(
        selectedModelCode.payload ? selectedModelCode.payload.toString() : ""
      );
      setSpecializedVehicle(
        selectedModelCode.carTypeName ? selectedModelCode.carTypeName : ""
      );
    }
  }, [selectedModelCode]);

  useEffect(() => {
    if (!selectedModelCode) {
      setSelectedModelCode({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      setWeight("");
      setOtherValue("");
    }
  }, [selectedModelCode]);

  useEffect(() => {
    (async () => {
      const getCarInfo: CarTypeCompanyInfo[] = await getCarInfoCompanyByType(
        "specialized",
        1000
      );
      let newListOption: SelectOption[] = [];
      if (getCarInfo && getCarInfo.length > 0) {
        getCarInfo.forEach((company) => {
          const option: SelectOption = {
            id: company.companyKey,
            label: company.companyName,
          };

          newListOption.push(option);
        });
        listHideCarBrand.forEach((element) => {
          newListOption = newListOption.filter(
            (option) => option.id !== element
          );
        });

        setListCarCompany(newListOption);
      }
    })();
  }, [listHideCarBrand]);

  useEffect(() => {
    if (selectedCarCompany && selectedCarCompany.id !== "") {
      setSelectedModel({ id: "", label: "" });
      setListCarInfoModel([]);
      (async () => {
        const listCarInfoModel: CarInfoModelType[] =
          await getCarInfoModelByType(
            "specialized",
            selectedCarCompany?.id || "",
            1000
          );
        const newListOption: SelectOption[] = [];

        if (listCarInfoModel && listCarInfoModel.length > 0) {
          listCarInfoModel.forEach((model) => {
            const option: SelectOption = {
              id: model.versionKey,
              label: model.versionName,
            };
            newListOption.push(option);
          });

          setListCarInfoModel(newListOption);
          if (isLoadDataFrLocal) {
            setSelectedModel(data.model);
          }
        }
      })();
    } else {
      setSelectedModel({ id: "", label: "" });
    }
  }, [selectedCarCompany]);

  useEffect(() => {
    if (
      selectedModel &&
      selectedModel.id !== "" &&
      selectedCarCompany &&
      selectedCarCompany.id !== ""
    ) {
      setSelectedModelCode({ id: "", label: "" });
      setListModelCode([]);
      (async () => {
        const listModelCode: CarModelCodeType[] = await getCarModelCodeByType(
          "specialized",
          selectedCarCompany.id,
          selectedModel.id,
          1000
        );
        const newListModelCode: SelectOption[] = [];
        if (listModelCode && listModelCode.length > 0) {
          listModelCode.forEach((item) => {
            const option: SelectOption = {
              id: item.modelCode,
              label: item.modelCode,
              carTypeName: item.carTypeName,
              payload: item.payload,
            };
            newListModelCode.push(option);
          });
          newListModelCode.push({ id: "other", label: "Khác" });
          setListModelCode(newListModelCode);

          if (isLoadDataFrLocal) {
            setSelectedModelCode(data.modelCode);
            setWeight(data.weight);
            setSelectedManufactured(data.selectedManufactured);
            setSpecializedVehicle(data.specializedVehicle);
            if (data.modelCode.id === "other") {
              setOtherValue(data.otherValue);
            }
          }
        } else {
          setListModelCode([{ id: "other", label: "Khác" }]);
          if (isLoadDataFrLocal) {
            setSelectedModelCode(data.modelCode);
            setWeight(data.weight);
            setSelectedManufactured(data.selectedManufactured);
            setSpecializedVehicle(data.specializedVehicle);
            if (data.modelCode.id === "other") {
              setOtherValue(data.otherValue);
            }
          }
        }
      })();
    } else {
      setSelectedModelCode({ id: "", label: "" });
    }
  }, [selectedModel, selectedCarCompany]);

  useEffect(() => {
    if (data) {
      setSelectedCarCompany(data.company);
      const evaluate = async () => {
        try {
          setIsLoadCarPrice(true);
          const value = await getPriceV3(
            data.modelCode.id === "other"
              ? {
                  vehicleTypeKey: localStorage.getItem("type"),
                  companyName: data.company.id,
                  versionName: data.model.id,
                  modelCode: "khac",
                  manufactureYear: data.selectedManufactured.id,
                  modelCodeFromUser: data.otherValue,
                }
              : {
                  vehicleTypeKey: localStorage.getItem("type"),
                  companyName: data.company.id,
                  versionName: data.model.id,
                  modelCode: data.modelCode.id,
                  manufactureYear: data.selectedManufactured.id,
                }
          );
          setCarPriceValue({
            carCompany: "",
            carModel: "",
            carVersion: "",
            year: "",
            maxPrice: value.maxPrice,
            carValue: value.price,
            minPrice: value.minPrice,
            isWarning: false,
            listedPrice: null,
            minListedPrice: null,
            maxListedPrice: null,
            hanoiOnRoadPrice: null,
            generalOnRoadPrice: null,
            hcmOnRoadPrice: null,
            additions: [],
          });
          setPriceVolatility([]);
          setCarEstimatedPrice({
            avgPriceSlippage: 0,
            avgPriceAfterNextYear: 0,
            percentPriceReduceAfterNextYear: 0,
            priceReduceAfterNextYear: 0,
            avgPriceAfterNext2Year: 0,
            percentPriceReduceAfterNext2Year: 0,
            priceReduceAfterNext2Year: 0,
          });
          setTimeout(() => setIsLoadCarPrice(false), 1000);
        } catch (error) {
          setIsLoadCarPrice(false);
        }
      };

      evaluate();
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (
        selectedCarCompany &&
        selectedModel &&
        selectedModelCode &&
        selectedManufactured &&
        selectedCarCompany.id === data.company.id &&
        selectedModel.id === data.model.id &&
        selectedModelCode.id === data.modelCode.id &&
        // otherValue === data.otherValue &&
        weight === data.weight &&
        specializedVehicle === data.specializedVehicle &&
        selectedManufactured.id === data.selectedManufactured.id
      ) {
        setIsLoadDataFrLocal(false);
      }
    } else {
      setIsLoadDataFrLocal(false);
    }
  }, [
    selectedCarCompany,
    selectedModel,
    selectedModelCode,
    otherValue,
    weight,
    specializedVehicle,
    selectedManufactured,
  ]);

  useEffect(() => {
    onValueChange(
      JSON.stringify({
        company: selectedCarCompany,
        model: selectedModel,
        modelCode: selectedModelCode,
        weight,
        otherValue,
        specializedVehicle,
        selectedManufactured,
      })
    );
  }, [
    selectedCarCompany,
    selectedModel,
    selectedModelCode,
    otherValue,
    weight,
    specializedVehicle,
    selectedManufactured,
  ]);

  const isValidate =
    selectedCarCompany &&
    selectedCarCompany.id !== "" &&
    selectedModel &&
    selectedModel.id !== "" &&
    specializedVehicle !== "" &&
    selectedManufactured &&
    selectedManufactured.id !== "" &&
    ((selectedModelCode &&
      selectedModelCode.id !== "" &&
      selectedModelCode.id !== "other") ||
      (selectedModelCode &&
        selectedModelCode.id === "other" &&
        otherValue !== ""));

  return (
    <>
      {isTabletOrMobile && (
        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant="outlined"
            endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            sx={{
              color: theme.palette.mode === "dark" ? "white" : "black",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
            }}
            onClick={() => setOpen(!open)}
          >
            Tra cứu
          </Button>
        </Grid>
      )}
      {(open || !isTabletOrMobile) && (
        <>
          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.brand.title")}
              listOption={listCarCompany}
              onChange={setSelectedCarCompany}
              value={selectedCarCompany}
              disabled={false}
              placeholder={t("form.selection.brand.placeholder")}
              onClose={() => {
                setOpenCarModel(true);
              }}
              onOpen={() => {
                setOpenCarModel(false);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={"Hiệu xe - Phiên bản"}
              listOption={listCarInfoModel}
              onChange={setSelectedModel}
              value={selectedModel}
              disabled={
                selectedCarCompany && selectedCarCompany.id !== ""
                  ? false
                  : true
              }
              placeholder={t("form.selection.model.placeholder")}
              open={
                selectedCarCompany &&
                selectedCarCompany.id !== "" &&
                openCarModel
              }
              onClose={() => {
                setOpenCarModel(false);
              }}
              onOpen={() => {
                setOpenCarModel(!openCarModel);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInputWithConditionalTextField
              label={"Số loại"}
              listOption={listModelCode}
              onChange={setSelectedModelCode}
              otherValue={otherValue}
              onChangeOtherValue={setOtherValue}
              value={selectedModelCode}
              disabled={selectedModel && selectedModel.id !== "" ? false : true}
              placeholder="Số loại"
              open={selectedModel && selectedModel.id !== "" && openModelCode}
              onClose={() => {
                setOpenModelCode(false);
              }}
              onOpen={() => {
                setOpenModelCode(!openModelCode);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.6} md={12}>
            <NumberInput
              name="weight"
              label={"Tải trọng"}
              onChange={setWeight}
              value={weight}
              disabled={
                selectedModelCode && selectedModelCode.id !== "" ? false : true
              }
              placeholder="Tải trọng"
            />
          </Grid>
          <Grid item xs={12} sm={5.6} md={12}>
            <TextFieldInput
              label={"Loại xe chuyên dụng"}
              onChange={setSpecializedVehicle}
              value={specializedVehicle}
              disabled={
                selectedModelCode && selectedModelCode.id === "other"
                  ? false
                  : true
              }
              placeholder="Loại xe chuyên dụng"
            />
          </Grid>
          <Grid item xs={12} sm={5.6} md={12}>
            <AutoCompleteInput
              label={t("form.selection.manufacture-year.title")}
              listOption={listOptionManufactureYear}
              onChange={setSelectedManufactured}
              value={selectedManufactured}
              placeholder="Năm sản xuất"
              disabled={
                selectedModelCode && selectedModelCode.id !== "" ? false : true
              }
            />
          </Grid>

          <Button
            style={{
              width: "100%",
              height: "50px",
              background: `${isValidate ? "#5768FF" : "#f0f0f0"}`,
              borderRadius: "12px",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              textAlign: "center",
              letterSpacing: "0.005em",
              color: `${isValidate ? "#ffffff" : "#bdbdbd"}`,
              textTransform: "unset",
              wordBreak: "keep-all",
              display: "block",
              margin: "0 auto",
            }}
            disabled={!isValidate}
            type="submit"
          >
            Tra cứu
          </Button>
        </>
      )}
    </>
  );
};

export default SpecializedVehicleForm;
