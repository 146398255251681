// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import axios from "axios";
import { clearLocalStorage } from "Common/common";

const jsondata = window._env_;
const { beInsuranceUrl, beReportUrl } = jsondata;

export const aiCycleRequest = axios.create({
  baseURL: beInsuranceUrl,
  withCredentials: true,
  headers: {
    aicApplicationId: "8837cfbc-11bd-43a3-bc34-177a59da64ad",
    "x-aicycle-application": "4067b10a-8ee9-47e7-8850-af36f8c74113",
  },
});

aiCycleRequest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const aiCycleEndUserRequest = axios.create({
  baseURL: beInsuranceUrl,
  withCredentials: true,
  headers: {
    aicApplicationId: "8837cfbc-11bd-43a3-bc34-177a59da64ad",
    "x-aicycle-application": "4067b10a-8ee9-47e7-8850-af36f8c74113",
  },
});

aiCycleEndUserRequest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const aiCycleImageRequest = axios.create({
  baseURL: beInsuranceUrl,
  withCredentials: true,
  headers: {
    contentType: "multipart/form-data",
    aicApplicationId: "8837cfbc-11bd-43a3-bc34-177a59da64ad",
    "x-aicycle-application": "4067b10a-8ee9-47e7-8850-af36f8c74113",
  },
});

aiCycleImageRequest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const aicycleRequestReport = axios.create({
  baseURL: beReportUrl,
  withCredentials: true,
  headers: {
    aicApplicationId: "8837cfbc-11bd-43a3-bc34-177a59da64ad",
    "x-aicycle-application": "4067b10a-8ee9-47e7-8850-af36f8c74113",
  },
});

aicycleRequestReport.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export async function getCarInfoCompany(limit: number) {
  try {
    const { data } = await aiCycleRequest.get(
      `/car-info/company?limit=${limit}`
    );
    return data.records;
  } catch (error) {
    clearLocalStorage();
    localStorage.removeItem("mode");
    window.location.href = "/login";
  }
}

export async function getCarInfoCompanyByType(type: string, limit: number) {
  try {
    const { data } = await aiCycleRequest.get(
      `/vehicles/${type}/company?limit=${limit}`
    );
    return data.records;
  } catch (error) {
    localStorage.removeItem("mode");
    clearLocalStorage();
    window.location.href = "/login";
  }
}

export async function getCarInfoModel(carCompanyId: string, limit: number) {
  const { data } = await aiCycleRequest.get(
    `/car-info/model?carCompanyId=${carCompanyId}&limit=${limit}`
  );
  return data.records;
}

export async function getCarInfoModelByType(
  type: string,
  carCompanyId: string,
  limit: number
) {
  const { data } = await aiCycleRequest.get(
    `/vehicles/${type}/versions?companyName=${carCompanyId}&limit=${limit}`
  );
  return data.records;
}

export async function getCarModelCodeByType(
  type: string,
  carCompanyKey: string,
  versionKey: string,
  limit: number
) {
  const { data } = await aiCycleRequest.get(
    `/vehicles/${type}/codebooks?companyKey=${carCompanyKey}&versionKey=${versionKey}&limit=${limit}`
  );
  return data.records;
}

export async function getBarelTypeTruck(isAll: boolean) {
  const { data } = await aiCycleRequest.get(
    `/vehicles/barrel?isAll=${isAll}&offset=0&limit=1000`
  );
  return data.records;
}

export async function getCarVersionV2(modelId: string) {
  const { data } = await aiCycleRequest.get(`/car-info/v2/versions/${modelId}`);
  return data;
}

export async function getCarVersion(
  companyId: string,
  modelId: string,
  year: string
) {
  const { data } = await aiCycleRequest.get(
    `/car-info/company/${companyId}/model/${modelId}/versions?year=${year}`
  );
  return data;
}

export async function getCarOption(companyId: string) {
  const { data } = await aiCycleRequest.get(
    `/internal/car-companies/${companyId}`
  );
  return data.options;
}

export async function getCarOptionByVersion(
  carCompany: string,
  carModel: string,
  carVersion: string,
  year: number
) {
  const { data } = await aiCycleRequest.get(
    `/car-info/options?carCompany=${carCompany}&carModel=${carModel}&carVersion=${carVersion}&year=${year}`
  );
  return data;
}

export async function getCarManufacturedYears(
  companyId: string,
  modelId: string
) {
  const { data } = await aiCycleRequest.get(
    `/car-info/company/${companyId}/model/${modelId}/manufactured-years`
  );
  return data;
}

export async function getPriceV2(valueCar: any) {
  const { data } = await aiCycleEndUserRequest.post(
    `valuation/v2/car-valuate`,
    valueCar
  );
  return data;
}

export async function getListOrigin(carObject: {
  carCompany: string;
  carModel: string;
  carVersion: string;
  year: number;
}) {
  const { data } = await aiCycleEndUserRequest.post(
    "car-info/origin-vehicle",
    carObject
  );
  return data.originInfos;
}

export async function getPrice(valueCar: any) {
  const { data } = await aiCycleEndUserRequest.post(
    `valuation/v2/car-valuate`,
    valueCar
  );
  return data;
}

export async function getPriceV3(valueCar: any) {
  const { data } = await aiCycleEndUserRequest.post(
    `valuation/v3/truck-valuate`,
    valueCar
  );
  return data;
}

export async function getInfoCarByImage(body: any) {
  const { data } = await aiCycleImageRequest.post(
    `/valuation/ocr/upload`,
    body
  );
  return data;
}

export async function getPriceOnRoad(
  carVersionKey: string,
  registrationPlace: string,
  optionKey?: string
) {
  const { data } = await aicycleRequestReport.get(
    optionKey
      ? `/value-me/onroad-price?carVersionKey=${carVersionKey}&registrationPlace=${registrationPlace}&optionKey=${optionKey}`
      : `/value-me/onroad-price?carVersionKey=${carVersionKey}&registrationPlace=${registrationPlace}`
  );
  return data;
}

export async function getPriceVolatility(
  carVersionKey: string,
  startDateTime?: string,
  endDateTime?: string
) {
  const { data } = await aicycleRequestReport.get(
    `/value-me/car-version-histories/volatility?carVersionKey=${carVersionKey}`
  );

  return data;
}

export async function getCarEstimatePrice(
  carVersionKey: string,
  startDateTime?: string,
  endDateTime?: string
) {
  const { data } = await aicycleRequestReport.get(
    `/value-me/car-estimate-price?carVersionKey=${carVersionKey}`
  );

  return data;
}

export async function getKmTraveledSuggestion(year: string) {
  const { data } = await aiCycleEndUserRequest.get(
    `valuation/km-traveled?year=${year}`
  );
  return data;
}
