import { Container, Grid, useMediaQuery } from "@mui/material";
import Banner from "Components/Banner";
import FormAICycle from "Components/MainBody/Form";
import SideBanner from "Components/MainBody/Sidebanner";
import Navbar from "Components/Navbar";
import Cookies from "js-cookie";
import LanguageContext from "languageContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export type ResultCarPrice = {
  carCompany: string;
  carModel: string;
  carVersion: string;
  year: string;
  maxPrice: number | null;
  carValue: number;
  minPrice: number | null;
  isWarning: boolean;
  listedPrice: number | null;
  minListedPrice: number | null;
  maxListedPrice: number | null;
  hanoiOnRoadPrice: number | null;
  generalOnRoadPrice: number | null;
  hcmOnRoadPrice: number | null;
  additions: Addition[];
};

export interface Addition {
  id: number;
  carVersionKey: string;
  carFuel: string;
  listedPrice: number;
  price: number;
  hanoiOnRoadPrice: number;
  generalOnRoadPrice: number;
  hcmOnRoadPrice: number;
  carValue: number;
  minPrice: number;
  maxPrice: number;
  minListedPrice: number;
  maxListedPrice: number;
  optionName: string | null;
  optionKey: string | null;
}

const EvaluatePage = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const [carPriceValue, setCarPriceValue] = useState<ResultCarPrice>();
  const [isLoadCarPrice, setIsLoadCarPrice] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const { language } = useContext(LanguageContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.language);
    }
  }, [i18n, language]);

  return (
    <div
      style={{
        height: "135vh",
      }}
    >
      <ToastContainer />
      <Container>
        <Navbar />
      </Container>
      <Banner />
      <div style={{ padding: `${isTabletOrMobile ? "0px" : "8px"}` }}>
        <div
          className="hai"
          style={{
            marginTop: `${isTabletOrMobile ? "0px" : "-120px"} `,
            padding: `${isTabletOrMobile ? "0px 0px" : "0px 120px"}`,
          }}
        >
          <Grid container>
            <Grid item sm={12} md={6} width="100%">
              <FormAICycle
                setCarPriceValue={setCarPriceValue}
                setIsLoadCarPrice={setIsLoadCarPrice}
                setError={setError}
              />
            </Grid>
            <Grid
              item
              justifyContent={"center"}
              alignItems={"start"}
              sm={12}
              md={6}
              width="100%"
            >
              <SideBanner
                carPriceValue={carPriceValue as ResultCarPrice}
                isLoadCarPrice={isLoadCarPrice}
                error={error}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EvaluatePage;
