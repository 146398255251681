export default function DownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M1.75 5.75L5.396 9.396C5.591 9.591 5.908 9.591 6.103 9.396L9.396 6.103C9.591 5.908 9.908 5.908 10.103 6.103L16.249 12.249"
        stroke="#D61F69"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 12.25H16.25V7.25"
        stroke="#D61F69"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
