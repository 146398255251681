import { createContext, Dispatch, SetStateAction } from "react";

interface ColorModeContextProps {
  mode: string;
  setMode: Dispatch<SetStateAction<string>>;
}

const ColorModeContext = createContext<ColorModeContextProps>({
  mode: "",
  setMode: () => {},
});

export default ColorModeContext;
