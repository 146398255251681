import { useState, useEffect, SetStateAction } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import { getPriceOnRoad } from "../../MainBody/api";
import { onRoadPriceResult } from "../types";
import { getDataFromLocalStorage } from "Common/common";
import { numberWithCommas } from "Common/formatNumber";
import "../style.css";

interface WheelAlignmentCostProps {
  onRoadPrice: {
    listedPrice: number;
    registrationFee: number;
    registrationFeePercent: number;
    licensePlateFee: number;
    vehicleInspectionFee: number;
    roadMaintenanceFee: number;
    liabilityInsurancePremium: number;
    totalPrice: number;
  };
  setOnRoadPrice: React.Dispatch<SetStateAction<onRoadPriceResult>>;
}

export default function WheelAlignmentCost({
  onRoadPrice,
  setOnRoadPrice,
}: WheelAlignmentCostProps) {
  const theme = useTheme();
  const data = getDataFromLocalStorage();
  const typeLocal = localStorage.getItem("type");

  const [purpose, setPurpose] = useState<string>("individual");
  const [registerPlace, setRegisterPlace] = useState<string>("hanoi");

  const onChangePurpose = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurpose((event.target as HTMLInputElement).value);
  };

  const onChangeRegisterPlace = (event: SelectChangeEvent) => {
    setRegisterPlace(event.target.value);
  };

  useEffect(() => {
    if (typeLocal === "car") {
      const getOnRoadPrice = async () => {
        try {
          const value = await getPriceOnRoad(
            data.version.key,
            registerPlace,
            data.optionKey?.key
          );
          setOnRoadPrice(value);
        } catch (error) {
          console.log(error);
        }
      };
      getOnRoadPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPlace, purpose]);
  return onRoadPrice.listedPrice ? (
    <Paper
      sx={{
        borderRadius: "16px",
        mt: "24px",
        p: "16px",
        backgroundImage: "unset",
        boxShadow: "none",
        backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#FFF",
      }}
    >
      <Grid container spacing={2} sx={{ paddingTop: "16px" }}>
        <Grid item md={12} sm={12} lg={4.8} xs={12}>
          <Typography sx={{ fontWeight: 600 }} variant="h4">
            Giá xe lăn bánh hiện tại ({data.manufactureyear?.id})
          </Typography>
          <FormControl sx={{ mt: "32px" }}>
            <FormLabel>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.mode === "light" ? "#171B21" : "#FFF",
                }}
              >
                Mục đích sử dụng
              </Typography>
            </FormLabel>
            <RadioGroup row value={purpose} onChange={onChangePurpose}>
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label={<Typography variant="h6">Cá nhân</Typography>}
              />
              <FormControlLabel
                value="enterpise"
                control={<Radio />}
                label={<Typography variant="h6">Doanh nghiệp</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ width: "80%", mt: "32px" }}>
            <Typography variant="h6" mb={1}>
              Nơi đăng ký
            </Typography>
            <Select
              size="small"
              value={registerPlace}
              onChange={onChangeRegisterPlace}
              style={{
                border:
                  theme.palette.mode === "dark" ? "1px solid #4B5563" : "unset",
              }}
            >
              <MenuItem key="hanoi" value="hanoi">
                Hà Nội
              </MenuItem>
              <MenuItem key="hcm" value="hcm">
                Hồ Chí Minh
              </MenuItem>
              <MenuItem key="general" value="general">
                Thành phố khác
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          md={12}
          lg={7.2}
          sm={12}
          xs={12}
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#171B22" : "#F9FAFB",
          }}
          p="24px"
          borderRadius="24px"
        >
          <Box className="display-space">
            <Typography variant="h5">Giá niêm yết</Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.listedPrice)}
            </Typography>
          </Box>
          <Box className="display-space" sx={{ mt: 1 }}>
            <Typography variant="h5">
              Phí trước bạ ({onRoadPrice.registrationFeePercent}%)
            </Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.registrationFee)}
            </Typography>
          </Box>
          <Box className="display-space" sx={{ mt: 1 }}>
            <Typography variant="h5">Phí sử dụng đường bộ (1 năm)</Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.roadMaintenanceFee)}
            </Typography>
          </Box>
          <Box className="display-space" sx={{ mt: 1 }}>
            <Typography variant="h5">
              Bảo hiểm trách nhiệm dân sự (01 năm)
            </Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.liabilityInsurancePremium)}
            </Typography>
          </Box>
          <Box className="display-space" sx={{ mt: 1 }}>
            <Typography variant="h5">Phí đăng ký biển số</Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.licensePlateFee)}
            </Typography>
          </Box>
          <Box className="display-space" sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h5">Phí đăng kiểm</Typography>
            <Typography sx={{ fontWeight: "600" }} variant="h5">
              {numberWithCommas(onRoadPrice.vehicleInspectionFee)}
            </Typography>
          </Box>
          <Divider />
          <Box className="display-space" sx={{ mt: 1 }}>
            <Typography variant="h5">Tổng cộng</Typography>
            <Typography variant="h2">
              {numberWithCommas(onRoadPrice.totalPrice)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
}
