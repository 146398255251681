import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface props {
  open: boolean;
  handleClose: () => void;
  handleLogout: () => void;
}

export default function LogoutDialog({
  open,
  handleClose,
  handleLogout,
}: props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Dialog maxWidth={"xs"} open={open} onClose={handleClose}>
        <DialogTitle>{t("logout.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("logout.body")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ textTransform: "unset" }}
          >
            {t("logout.cancel")}
          </Button>
          <Button
            onClick={handleLogout}
            variant="contained"
            sx={{ textTransform: "unset" }}
          >
            {t("logout.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
