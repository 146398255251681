const themeTypo = {
  typography: {
    fontFamily: "San Francisco Text", // Font chữ chính
    h1: {
      fontSize: "30px",
      fontWeight: 600,
      fontStyle: "normal",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
      fontStyle: "normal",
    },
    h3: {
      fontSize: "24px",
      fontWeight: 600,
      fontStyle: "normal",
    },
    h4: {
      fontSize: "20px",
      fontWeight: 500,
      fontStyle: "normal",
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
      fontStyle: "normal",
    },
    h6: {
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: 400,
      fontStyle: "normal",
    },
  },
};

export default themeTypo;
