import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import FileAdd from "assets/Images/file-add.png";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getInfoCarByImage } from "../MainBody/api";
import "./styles.css";
import { useTranslation } from "react-i18next";

const ImageUploadingFromLib = ({
  setDataFromImage,
  setCarPriceValue,
  resetSelect,
  setIsLoadImg,
  isLoadImg,
  files,
  setFiles,
}: {
  setDataFromImage: Function;
  setCarPriceValue: Function;
  resetSelect: Function;
  setIsLoadImg: Function;
  isLoadImg: boolean;
  files: any;
  setFiles: Function;
}) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      resetSelect();
      setCarPriceValue(undefined);

      const formData = new FormData();
      const file = acceptedFiles[0];
      formData.append("formFile", file);

      setIsLoadImg(true);
      try {
        const getByImg = await getInfoCarByImage(formData);
        if (getByImg.carCompany) {
          toast.success(t("upload.notification.success"), {
            position: "top-center",
            autoClose: 3000,
          });
          setDataFromImage(getByImg);
          setIsLoadImg(false);
        } else {
          toast.error(t("upload.notification.client-error"), {
            position: "top-center",
            autoClose: 3000,
          });
          setIsLoadImg(false);
        }
      } catch (error: any) {
        toast.error(`${t("upload.notification.server-error")}`, {
          position: "top-center",
          autoClose: 3000,
        });
        setIsLoadImg(false);
      }
    },
  });

  const handleClose = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
    resetSelect();
    setFiles([]);
  };

  return (
    <div>
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {files.length === 0 ? (
            <>
              <img src={FileAdd} alt=""></img>
              <p className="textCustom">{t("form.registry.title")}</p>
              <p className="textCustomGray">{t("form.registry.description")}</p>
              <p className="textCustomGray">{t("form.registry.file-format")}</p>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <img
                  src={files[0].preview}
                  style={{ height: "50px", width: "50px" }}
                  alt=""
                  onLoad={() => {
                    URL.revokeObjectURL(files[0].preview);
                  }}
                />
                <div
                  style={{
                    width: "calc(100% - 90px)",
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span>{files[0].name}</span>
                  {isLoadImg ? <LinearProgress /> : null}
                </div>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={(e) => {
                    handleClose(e);
                  }}
                  style={{ height: 50, width: 50 }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
              {isLoadImg ? null : (
                <span className="textSuggest">
                  ({t("form.registry.dropdown-description")})
                </span>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ImageUploadingFromLib;
