export const formatNumberPrice = (value: number | null) => {
  if (value === null) {
    return "Đang cập nhật";
  }
  if (value > 1000000000) {
    return value / 1000000000 + " Tỷ";
  } else {
    return value / 1000000 + " Triệu";
  }
};

export const formatNumberPriceEstimate = (value: number | null) => {
  if (value === null) {
    return "Đang cập nhật";
  }
  if (value > 1000000000) {
    return (value / 1000000000).toFixed(3) + " Tỷ";
  } else {
    return (value / 1000000).toFixed(2) + " Triệu";
  }
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
