//@ts-nocheck
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Avatar,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { stringAvatar } from "../../Common/common";
import { useContext, useState } from "react";
import LanguageContext from "languageContext";
import UserContext from "userContext";
import ColorModeContext from "ColorModeContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/Images/logo-small.png";
import englandFlag from "../../assets/Images/flags/englandFlag.png";
import vietnamFlag from "../../assets/Images/flags/vietnamFlag.png";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { clearLocalStorage } from "../../Common/common";
import LogoutDialog from "Components/FormComponents/Dialog";

export default function Header() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery("(max-width: 495px)");
  const { language, setLanguage } = useContext(LanguageContext);
  const { mode, setMode } = useContext(ColorModeContext);
  const [open, setOpen] = useState(false);
  const handleChangeLanguage = (language: string) => {
    setLanguage({ language });
    i18n.changeLanguage(language);
  };
  const handleChaneModeView = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    localStorage.setItem("mode", mode === "light" ? "dark" : "light");
  };
  const jsondata = window._env_;
  const { t, i18n } = useTranslation();
  const handleLogout = () => {
    const { beAdminUrl } = jsondata;
    const logout = async () => {
      await fetch(`${beAdminUrl}/logout`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("mode");
          setMode("light");
          setUser(null);
          clearLocalStorage();
          navigate("/login");
          return;
        }
      });
    };
    logout();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: !isTabletOrMobile ? "40px" : "15px",
      }}
    >
      <img src={logo} width="136px" height="32px" alt="" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          paddingY: "20px",
        }}
      >
        <IconButton sx={{ mr: 1 }} onClick={handleChaneModeView}>
          {mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <FormControl>
          <Select
            defaultValue={language?.language || "vi"}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              ".MuiSelect-icon": { display: "none" },
            }}
            disabled
            variant="standard"
            disableUnderline
            onChange={(e) => handleChangeLanguage(e.target.value as string)}
          >
            <MenuItem value="vi">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={vietnamFlag}
                  alt="Vietnamese flag"
                  width={25.6}
                  height={17.9}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "San Francisco Text",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  VI
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="en">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={englandFlag}
                  alt="England flag"
                  width={25.6}
                  height={17.9}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "San Francisco Text",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  EN
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />
        {!isTabletOrMobile && (
          <Typography
            sx={{
              color: "white",
              fontFamily: "San Francisco Text",
              fontSize: "14px",
            }}
          >
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
        )}
        <Tooltip title={t("auth.logout.title")} placement="bottom">
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              "&:hover": {
                backgroundColor: "transparent",
                opacity: 1,
              },
            }}
            onClick={() => setOpen(true)}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <LogoutDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleLogout={handleLogout}
      />
    </Box>
  );
}
