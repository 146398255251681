import {
  Grid,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Box,
} from "@mui/material";
import { BannerProps } from "Components/Banner";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { memo, useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CarForm from "./CarType/Car";
import TruckForm from "./CarType/Truck";
import CoachForm from "./CarType/Coach";
import SpecializedVehicle from "./CarType/SpecializedVehicle";

const CoverBox = styled.div<BannerProps>`
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(102, 105, 133, 0.2);
  border-radius: 24px;
  position: relative;
  top: ${(props) => (props.isTabletOrMobile ? "10%" : "-10%")};
`;

export type SelectOption = {
  id: string;
  label: string;
  key?: string;
  fuel?: string;
};

export type CarCompanyInfo = {
  carCompanyId: string;
  vehicleBrandName: string;
};

export type CarInfoModel = {
  carModelId: string;
  carModelName: string;
  year: number;
};

export type CarOption = {
  carOptionId: string;
  carOptionKey: string;
  optionName: string;
};

interface formProps {
  onSendType: (value: string) => void;
}

const FormAICycle = ({ onSendType }: formProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataStringify, setDataStringify] = useState<string>("");
  const [carType, setCarType] = useState<string>("car");

  const tablet = useMediaQuery(theme.breakpoints.up("md"));

  const handleValueChange = (value: string) => {
    setDataStringify(value);
  };

  const handleChangeCarType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarType((event.target as HTMLInputElement).value);
    onSendType((event.target as HTMLInputElement).value);
  };

  const initialValues = {
    carCompanyId: "",
  };

  const handleSubmit = () => {
    localStorage.setItem(`${carType}`, dataStringify);
    localStorage.setItem("type", carType);
    navigate("/result");
  };

  return (
    <CoverBox
      isTabletOrMobile={true}
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#ffffff",
      }}
    >
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {() => {
          return (
            <Form>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={`${matches ? "20px" : "40px"}`}
                rowGap={`${matches ? "20px" : "10px"}`}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      color: theme.palette.mode === "dark" ? "#FFF" : "#111",
                      fontSize: 20,
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    {t("form.title.main")}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={carType}
                      onChange={handleChangeCarType}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <FormControlLabel
                          value="car"
                          control={<Radio size="small" />}
                          label="Xe con"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "#FFF"
                                : "#171B21",
                          }}
                        />
                        <FormControlLabel
                          value="truck"
                          control={<Radio size="small" />}
                          label="Xe chở hàng hoá"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "#FFF"
                                : "#171B21",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: tablet ? 10 : 0,
                        }}
                      >
                        <FormControlLabel
                          value="specialized"
                          control={<Radio size="small" />}
                          label="Xe chuyên dụng"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "#FFF"
                                : "#171B21",
                          }}
                        />
                        <FormControlLabel
                          value="coach"
                          control={<Radio size="small" />}
                          label="Xe khách"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "#FFF"
                                : "#171B21",
                          }}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#4B5563" : "#D1D5DB",
                    }}
                  />
                </Grid>

                {carType === "car" && (
                  <CarForm onValueChange={handleValueChange} />
                )}
                {carType === "truck" && (
                  <TruckForm onValueChange={handleValueChange} />
                )}
                {carType === "coach" && (
                  <CoachForm onValueChange={handleValueChange} />
                )}
                {carType === "specialized" && (
                  <SpecializedVehicle onValueChange={handleValueChange} />
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </CoverBox>
  );
};

export default memo(FormAICycle);
