/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "userContext";

const useCheckLogin = () => {
  const { beAdminUrl } = window._env_;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setUser, user } = useContext(UserContext);

  useEffect(() => {
    const checkLogin = async () => {
      const res = await fetch(`${beAdminUrl}/bearer`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await res.json();
      if (res.ok && result.data.status === "active") {
        if (!user) {
          setUser({
            firstName: result.data.profile.firstname,
            lastName: result.data.profile.lastname,
            primaryUname: result.data.primaryUname,
            ocrEnable:
              result.data.organizations[0].kvp.settings.enableOcrValuation,
          });
        }
        navigate(pathname === "/login" ? "/" : pathname, { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    };
    checkLogin();
  }, [beAdminUrl, navigate, pathname]);
};

export default useCheckLogin;
