import styled from "styled-components";

export const LabelForAuto = styled.span`
  font-family: "San Francisco Text";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.00938em;
  padding: 5px 0px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
`;
