import Box from "@mui/material/Box";
import { Typography, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconAI from "../icons/IconAI";
import DownIcon from "../icons/DownIcon";
import IconUptrend from "../icons/IconUptrend";
import { CarPriceInflation, carEstimatePrice } from "../types";
import {
  formatNumberPrice,
  formatNumberPriceEstimate,
} from "Common/formatNumber";
import { getCurrentTime, checkFirstRecord, subtraction } from "Common/common";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import "./styles.css";

interface chartProps {
  priceVolatility: CarPriceInflation[];
  carEstimatePrice: carEstimatePrice;
  error: boolean;
  errorEstimated: boolean;
}

export default function Chart({
  priceVolatility,
  carEstimatePrice,
  error,
  errorEstimated,
}: chartProps) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();

  const listPrice = priceVolatility.map(
    (item: { dateTime: string; price: number }) => item.price
  );

  const date = new Date().getFullYear();

  const passData = priceVolatility.filter(
    (item: { dateTime: string; price: number }) => {
      const arr = item.dateTime.split("-");
      return (
        Number(arr[0]) < new Date().getFullYear() ||
        (Number(arr[1]) <= new Date().getMonth() + 1 &&
          Number(arr[0]) === new Date().getFullYear())
      );
    }
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const dataSelected = payload[0];
      return (
        <div
          style={{
            backgroundColor:
              theme.palette.mode === "light" ? "#FFF" : "#21262C",
            padding: "12px",
            borderRadius: "8px",
            border:
              theme.palette.mode === "light"
                ? "1px solid #E5E7EB"
                : "1px solid #4B5563",
            boxShadow: "0px 2px 8px 0px rgba(18, 25, 65, 0.10)",
          }}
        >
          <p
            style={{
              fontSize: "10px",
              color: theme.palette.mode === "light" ? "#9CA3AF" : "#9CA3AF",
            }}
          >
            {dataSelected.payload.dateTime}
          </p>
          <p
            style={{
              fontSize: "12px",
              color: theme.palette.mode === "light" ? "#171B21" : "#FFF",
              fontWeight: "600",
            }}
          >
            {formatNumberPrice(dataSelected.payload.price)}
          </p>
          {!checkFirstRecord(dataSelected.payload, priceVolatility) &&
            subtraction(dataSelected.payload, priceVolatility).sub !== 0 && (
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontSize: "10px",
                }}
              >
                {subtraction(dataSelected.payload, priceVolatility).sub < 0 ? (
                  <DownIcon />
                ) : (
                  <IconUptrend />
                )}
                <span
                  style={{
                    color:
                      subtraction(dataSelected.payload, priceVolatility).sub > 0
                        ? "#31C48D"
                        : "#D61F69",
                    fontSize: "10px",
                  }}
                >
                  {(
                    (Math.abs(
                      subtraction(dataSelected.payload, priceVolatility).sub
                    ) *
                      100) /
                    priceVolatility[
                      subtraction(dataSelected.payload, priceVolatility).index
                    ].price
                  ).toFixed(2)}
                  %
                </span>
                <span>
                  {subtraction(dataSelected.payload, priceVolatility).sub > 0
                    ? `+(${formatNumberPrice(
                        subtraction(dataSelected.payload, priceVolatility).sub
                      )})`
                    : `(${formatNumberPrice(
                        subtraction(dataSelected.payload, priceVolatility).sub
                      )})`}
                </span>
              </p>
            )}
        </div>
      );
    }
    return null;
  };
  const DataFormater = (value: any) => {
    const min = Math.min(...listPrice);
    const price = parseInt(value, 10);
    if (min > 1000000000) {
      return (price / 1000000000).toFixed(1).toString();
    }

    return (price / 1000000).toFixed(0).toString();
  };

  const checkDisplayChart = (error: boolean, data: number) => {
    if (error === true || data === 0) {
      return false;
    }
    return true;
  };

  return (
    <Paper
      sx={{
        borderRadius: "16px",
        mt: "24px",
        p: "16px",
        boxShadow: "none",
        backgroundImage: "unset",
        backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#FFF",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: 2 }}>
        Biểu đồ trượt giá
      </Typography>
      {checkDisplayChart(error, priceVolatility.length) && (
        <ResponsiveContainer width="100%" height={isMobile ? "50%" : "70%"}>
          <AreaChart data={priceVolatility} margin={{ top: 30 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="45%"
                  stopColor="rgba(77, 99, 255, 0.50)"
                  stopOpacity={1}
                />
                <stop
                  offset="95%"
                  stopColor="rgba(77, 99, 255, 0.50)"
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateTime"
              style={{ fontSize: "10px" }}
              allowDuplicatedCategory={false}
              tick={{
                fill: theme.palette.mode === "dark" ? "#FFF" : "#171B21",
              }}
            />
            <YAxis
              style={{
                fontSize: "13px",
              }}
              domain={[
                Math.min(...listPrice) - 20000000,
                Math.max(...listPrice) + 20000000,
              ]}
              tickFormatter={DataFormater}
              tick={{
                fill: theme.palette.mode === "dark" ? "#FFF" : "#171B21",
              }}
              label={{
                value: `${
                  Math.min(...listPrice) >= 1000000000 ? "Tỷ VND" : "Triệu VND"
                }`,
                position: "insideTopLeft",
                dy: -30,
                dx: 10,
                fill: theme.palette.mode === "dark" ? "#FFF" : "#171B21",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              activeDot={false}
              dot={{ stroke: "rgba(77, 99, 255, 0.50)", strokeWidth: 3 }}
              type="monotone"
              dataKey="price"
              data={passData}
              strokeWidth={4}
              fillOpacity={1}
              stroke="rgba(77, 99, 255, 0.50)"
              fill="url(#colorUv)"
            />
            <Area
              activeDot={false}
              dot={{ stroke: "rgba(77, 99, 255, 0.50)", strokeWidth: 3 }}
              type="monotone"
              dataKey="price"
              data={priceVolatility}
              strokeWidth={4}
              fillOpacity={0}
              stroke="rgba(77, 99, 255, 0.50)"
              fill="url(#colorUv)"
            />
            <ReferenceLine
              x={getCurrentTime()}
              stroke="rgba(77, 99, 255, 0.50)"
              strokeWidth={2}
              strokeDasharray="3 3"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      {(error || priceVolatility.length === 0) && (
        <p style={{ fontSize: 18, textAlign: "center" }}>Không có dữ liệu</p>
      )}
      <Box sx={{ display: "flex", justifyItems: "center", mt: 2 }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "700", mr: 2 }}>
          Giá ước tính
        </Typography>
        <span
          style={{
            fontSize: "12px",
            color: theme.palette.mode === "dark" ? "#C5CDFF" : "#4E65FF",
            backgroundColor:
              theme.palette.mode === "dark" ? "#2F3455" : "#EBF5FF",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            padding: "4px 12px",
            fontWeight: 500,
          }}
        >
          <IconAI
            color={theme.palette.mode === "dark" ? "#C5CDFF" : "#3F83F8"}
          />
          &nbsp; AI Phân tích
        </span>
      </Box>
      <Grid container sx={{ mt: "16px" }} spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper
            sx={{
              paddingX: "24px",
              paddingY: "20px",
              borderRadius: "12px",
              minHeight: "125px",
              boxShadow: "none",
              border:
                theme.palette.mode === "dark"
                  ? "1px solid #2F353C"
                  : "1px solid #F3F4F6",
              backgroundImage: "unset",
              backgroundColor:
                theme.palette.mode === "dark" ? "#21262C" : "unset",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? "#D1D5DB" : "#4B5563",
              }}
              variant="h6"
            >
              Trượt giá trung bình
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
              {errorEstimated ? (
                <Typography variant="h5">Không có dữ liệu</Typography>
              ) : (
                <>
                  <Typography
                    sx={{ fontWeight: "700" }}
                    variant="h4"
                    className={`${
                      carEstimatePrice.avgPriceSlippage &&
                      carEstimatePrice.avgPriceSlippage > 0
                        ? "green"
                        : "red"
                    }`}
                  >
                    {`${
                      carEstimatePrice.avgPriceSlippage !== null
                        ? carEstimatePrice.avgPriceSlippage.toString() + "%"
                        : ""
                    }`}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    / năm
                  </Typography>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper
            sx={{
              paddingX: "24px",
              paddingY: "20px",
              borderRadius: "12px",
              minHeight: "125px",
              boxShadow: "none",
              border:
                theme.palette.mode === "dark"
                  ? "1px solid #2F353C"
                  : "1px solid #F3F4F6",
              backgroundImage: "unset",
              backgroundColor:
                theme.palette.mode === "dark" ? "#21262C" : "unset",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? "#D1D5DB" : "#4B5563",
              }}
              variant="h6"
            >
              1 năm tới ({date + 1})
            </Typography>
            {errorEstimated ? (
              <Typography variant="h5" sx={{ mt: 1 }}>
                Không có dữ liệu
              </Typography>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#F9FAFB" : "#171B21",
                      fontWeight: "700",
                    }}
                    variant="h4"
                  >
                    {carEstimatePrice.avgPriceAfterNextYear !== null &&
                      formatNumberPriceEstimate(
                        carEstimatePrice.avgPriceAfterNextYear
                      )}
                  </Typography>
                  <Typography sx={{ color: "#9CA3AF" }} variant="h5">
                    VND
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}
                >
                  {carEstimatePrice.percentPriceReduceAfterNextYear &&
                  carEstimatePrice.percentPriceReduceAfterNextYear > 0 ? (
                    <IconUptrend />
                  ) : (
                    <DownIcon />
                  )}
                  <Typography
                    sx={{ fontWeight: "600" }}
                    variant="subtitle1"
                    className={`${
                      carEstimatePrice.percentPriceReduceAfterNextYear &&
                      carEstimatePrice.percentPriceReduceAfterNextYear > 0
                        ? "green"
                        : "red"
                    }`}
                  >
                    {carEstimatePrice.percentPriceReduceAfterNextYear !==
                      null &&
                      carEstimatePrice.percentPriceReduceAfterNextYear.toString() +
                        "%"}
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#F9FAFB" : "#171B21",
                    }}
                    variant="subtitle1"
                  >
                    (
                    {carEstimatePrice.priceReduceAfterNextYear !== null &&
                      formatNumberPriceEstimate(
                        carEstimatePrice.priceReduceAfterNextYear
                      )}{" "}
                    )
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper
            sx={{
              paddingX: "24px",
              paddingY: "20px",
              borderRadius: "12px",
              minHeight: "125px",
              boxShadow: "none",
              border:
                theme.palette.mode === "dark"
                  ? "1px solid #2F353C"
                  : "1px solid #F3F4F6",
              backgroundImage: "unset",
              backgroundColor:
                theme.palette.mode === "dark" ? "#21262C" : "unset",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? "#D1D5DB" : "#4B5563",
              }}
              variant="h6"
            >
              2 năm tới ({date + 2})
            </Typography>
            {errorEstimated ? (
              <Typography variant="h5" sx={{ mt: 1 }}>
                Không có dữ liệu
              </Typography>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#F9FAFB" : "#171B21",
                      fontWeight: "700",
                    }}
                    variant="h4"
                  >
                    {carEstimatePrice.avgPriceAfterNext2Year !== null &&
                      formatNumberPriceEstimate(
                        carEstimatePrice.avgPriceAfterNext2Year
                      )}{" "}
                  </Typography>
                  <Typography sx={{ color: "#9CA3AF" }} variant="h5">
                    VND
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}
                >
                  {carEstimatePrice.percentPriceReduceAfterNext2Year &&
                  carEstimatePrice.percentPriceReduceAfterNext2Year > 0 ? (
                    <IconUptrend />
                  ) : (
                    <DownIcon />
                  )}
                  <Typography
                    sx={{ fontWeight: "600" }}
                    variant="subtitle1"
                    className={`${
                      carEstimatePrice.percentPriceReduceAfterNext2Year &&
                      carEstimatePrice.percentPriceReduceAfterNext2Year > 0
                        ? "green"
                        : "red"
                    }`}
                  >
                    {carEstimatePrice.percentPriceReduceAfterNext2Year !==
                      null &&
                      carEstimatePrice.percentPriceReduceAfterNext2Year.toString()}
                    %
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#F9FAFB" : "#171B21",
                    }}
                    variant="subtitle1"
                  >
                    (
                    {carEstimatePrice.priceReduceAfterNext2Year !== null &&
                      formatNumberPriceEstimate(
                        carEstimatePrice.priceReduceAfterNext2Year
                      )}
                    )
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
