import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { BannerProps } from "Components/Banner";
import { ResultCarPrice } from "Components/MainBody";
import { Form, Formik } from "formik";
import { useContext, useEffect, useMemo, useState, memo } from "react";
import styled from "styled-components";
import AutoCompleteInput from "../../Components/FormComponents/AutoCompleteInput";
// import TextFieldInput from "Components/FormComponents/TextFieldInput";
import ImageUploadingFromLib from "Components/ImageUploading";
import { useTranslation } from "react-i18next";
import UserContext from "userContext";
import {
  getCarInfoCompany,
  getCarInfoModel,
  getCarManufacturedYears,
  getCarVersion,
  getPrice,
} from "./api";

const FormTitle = styled.div`
  /* font-family: "San Francisco Text"; */
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
  font-family: "San Francisco Text";
  /* font-style: italic; */
`;

const CoverBox = styled.div<BannerProps>`
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(102, 105, 133, 0.2);
  border-radius: 24px;
  position: relative;
  top: ${(props) => (props.isTabletOrMobile ? "10%" : "-10%")};
`;

const NoteText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1f2738;
  margin-bottom: 8px;
  text-align: center;
  font-family: "San Francisco Text";
`;

export type SelectOption = {
  id: string;
  label: string;
  key?: string;
  fuel?: string;
};

export type CarCompanyInfo = {
  carCompanyId: string;
  vehicleBrandName: string;
};

export type CarInfoModel = {
  carModelId: string;
  carModelName: string;
  year: number;
};

const FormAICycle = ({
  setCarPriceValue,
  setIsLoadCarPrice,
  setError,
}: {
  setCarPriceValue: React.Dispatch<
    React.SetStateAction<ResultCarPrice | undefined>
  >;
  setIsLoadCarPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [selectedCarCompany, setSelectedCarCompany] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [listCarCompany, setListCarCompany] = useState<SelectOption[]>([]);
  const [listCarInfoModel, setListCarInfoModel] = useState<SelectOption[]>([]);
  const [selectedModel, setSelectedModel] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [listVersion, setListVersion] = useState<SelectOption[]>([]);
  const [listManufactured, setListManufactured] = useState<SelectOption[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedManufactured, setSelectedManufactured] =
    useState<SelectOption>({ id: "", label: "" });
  const [dataFormImage, setDataFromImage] = useState<any>();
  const [isLoadImg, setIsLoadImg] = useState(false);

  const [openCarModel, setOpenCarModel] = useState(false);
  const [openCarManufactured, setOpenCarManufactured] = useState(false);
  const [openCarVersion, setOpenCarVersion] = useState(false);

  const [correlationId, setCorrelationId] = useState<string>("");

  const initialValues = {
    carCompanyId: "",
  };

  const listHideCarBrand: string[] = useMemo(
    () => [
      "ed70991f-411c-4ac0-8ae4-0c7d66427e59",
      "06df3ccf-b96f-4c95-98fb-801b25295a66",
      "b7b77302-86a5-4d13-a621-d25bb9c48569",
      "f31e885d-dfc5-403c-beb2-da3d0a20c9ce",
      "20d8df48-232c-4711-9eac-b6fbf5d67253",
    ],
    []
  );

  useEffect(() => {
    (async () => {
      const getCarInfo: CarCompanyInfo[] = await getCarInfoCompany(1000);
      let newListOption: SelectOption[] = [];
      if (getCarInfo && getCarInfo.length > 0) {
        getCarInfo.forEach((company) => {
          const option: SelectOption = {
            id: company.carCompanyId,
            label: company.vehicleBrandName,
          };

          newListOption.push(option);
        });
        listHideCarBrand.forEach((element) => {
          newListOption = newListOption.filter(
            (option) => option.id !== element
          );
        });

        setListCarCompany(newListOption);
      }

      if (dataFormImage) {
        const nameOfCarCompany = dataFormImage.predictedCarCompany;
        const findCarCompany = newListOption.find((company) =>
          company?.label?.includes(nameOfCarCompany)
        );

        if (findCarCompany) {
          setSelectedCarCompany(findCarCompany);
        }
      }
    })();
  }, [listHideCarBrand, dataFormImage]);

  useEffect(() => {
    if (selectedCarCompany && selectedCarCompany.id !== "") {
      setSelectedModel({ id: "", label: "" });
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      (async () => {
        const listCarInfoModel: CarInfoModel[] = await getCarInfoModel(
          selectedCarCompany?.id || "",
          100
        );
        const newListOption: SelectOption[] = [];

        if (listCarInfoModel && listCarInfoModel.length > 0) {
          listCarInfoModel.forEach((model) => {
            const option: SelectOption = {
              id: model.carModelId,
              label: model.carModelName,
            };
            newListOption.push(option);
          });

          setListCarInfoModel(newListOption);
        }
        if (dataFormImage) {
          const nameOfCarModel = dataFormImage.predictedCarModel;
          const findCarModel = newListOption.find((model) =>
            model?.label?.includes(nameOfCarModel)
          );
          if (findCarModel) {
            setSelectedModel(findCarModel);
          }
        }
      })();
    }
  }, [selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedModel && selectedModel.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      (async () => {
        const listManufacturedYears: number[] = await getCarManufacturedYears(
          selectedCarCompany.id,
          selectedModel.id
        );
        const newListManufactured: SelectOption[] = [];
        if (listManufacturedYears && listManufacturedYears.length > 0) {
          listManufacturedYears.forEach((manufactured) => {
            const option: SelectOption = {
              id: manufactured.toString(),
              label: manufactured.toString(),
            };
            newListManufactured.push(option);
          });
          setListManufactured(newListManufactured);
        }
        if (dataFormImage) {
          const nameOfManufactured = dataFormImage.predictedYear;
          const findManufactured = newListManufactured.find(
            (manufactured) =>
              manufactured?.label === nameOfManufactured.toString()
          );
          if (findManufactured) {
            setSelectedManufactured(findManufactured);
          }
        }
      })();
    }
  }, [selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedManufactured && selectedManufactured.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      (async () => {
        const listVersion = await getCarVersion(
          selectedCarCompany?.id,
          selectedModel.id,
          selectedManufactured.id
        );
        const newListOption: SelectOption[] = [];
        if (listVersion && listVersion.length > 0) {
          listVersion.forEach((version: any) => {
            const option: SelectOption = {
              id: version.carVersionId,
              label: version.carVersionName,
              fuel: version.carFuelName,
            };
            newListOption.push(option);
          });
          setListVersion(newListOption);
        }
        if (dataFormImage) {
          const nameOfCarVersion = dataFormImage.predictedCarVersion;
          const findCarVersion = newListOption.find((version) =>
            version?.label?.includes(nameOfCarVersion)
          );
          if (findCarVersion) {
            setSelectedVersion(findCarVersion);
          }
        }
      })();
    }
  }, [selectedManufactured, selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (files.length === 0) {
      setCorrelationId("");
      return;
    }
    if (dataFormImage) {
      const traceId = dataFormImage.correlationId;

      if (traceId) {
        setCorrelationId(traceId);
      }
    }
  }, [dataFormImage, files.length]);

  const handleSubmit = async () => {
    if (
      selectedCarCompany &&
      selectedCarCompany.id !== "" &&
      selectedModel &&
      selectedModel.id !== "" &&
      selectedVersion &&
      selectedVersion.id !== "" &&
      selectedManufactured &&
      selectedManufactured.id !== ""
    ) {
      setIsLoadCarPrice(true);

      const valueCar = correlationId
        ? {
            carCompany: selectedCarCompany.label,
            carModel: selectedModel.label,
            carVersion: selectedVersion.label,
            carVersionKey: selectedVersion.key,
            year: parseInt(selectedManufactured.id, 10),
            correlationId: correlationId,
          }
        : {
            carCompany: selectedCarCompany.label,
            carModel: selectedModel.label,
            carVersion: selectedVersion.label,
            carVersionKey: selectedVersion.key,
            year: parseInt(selectedManufactured.id, 10),
          };

      try {
        setError(false);
        const getCarPrice = await getPrice(valueCar);
        setCarPriceValue(getCarPrice);
        setIsLoadCarPrice(false);
      } catch (error: any) {
        setError(true);
        setIsLoadCarPrice(false);
        setCarPriceValue(undefined);
      }
    }
  };

  const isValidate =
    selectedCarCompany &&
    selectedCarCompany.id !== "" &&
    selectedModel &&
    selectedModel.id !== "" &&
    selectedVersion &&
    selectedVersion.id !== "" &&
    selectedManufactured &&
    selectedManufactured.id !== "";

  const resetSelect = () => {
    setSelectedCarCompany({ id: "", label: "" });
    setSelectedModel({ id: "", label: "" });
    setSelectedVersion({ id: "", label: "", key: "" });
    setSelectedManufactured({ id: "", label: "" });
    setCorrelationId("");
  };

  return (
    <CoverBox isTabletOrMobile={true}>
      <Formik
        onSubmit={() => {
          handleSubmit();
        }}
        initialValues={initialValues}
      >
        {() => {
          return (
            <Form>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={5}
                rowGap={"20px"}
              >
                <Grid item xs={12}>
                  <FormTitle>{t("form.title.main")}</FormTitle>
                </Grid>
                {user?.ocrEnable ? (
                  <Grid item xs={12}>
                    <NoteText>{t("form.title.description")}</NoteText>
                    <ImageUploadingFromLib
                      setDataFromImage={setDataFromImage}
                      setCarPriceValue={setCarPriceValue}
                      resetSelect={resetSelect}
                      setIsLoadImg={setIsLoadImg}
                      isLoadImg={isLoadImg}
                      files={files}
                      setFiles={setFiles}
                    />
                  </Grid>
                ) : null}
                <Grid item md={5.5} xs={12}>
                  <AutoCompleteInput
                    label={t("form.selection.brand.title")}
                    listOption={listCarCompany}
                    onChange={setSelectedCarCompany}
                    value={selectedCarCompany}
                    disabled={false}
                    placeholder={t("form.selection.brand.placeholder")}
                    onClose={() => {
                      setOpenCarModel(true);
                    }}
                    onOpen={() => {
                      setOpenCarModel(false);
                      setOpenCarVersion(false);
                    }}
                    files={files}
                    setFiles={setFiles}
                  />
                </Grid>

                <Grid item md={5.5} xs={12}>
                  <AutoCompleteInput
                    label={t("form.selection.model.title")}
                    listOption={listCarInfoModel}
                    onChange={setSelectedModel}
                    value={selectedModel}
                    disabled={
                      selectedCarCompany && selectedCarCompany.id !== ""
                        ? false
                        : true
                    }
                    placeholder={t("form.selection.model.placeholder")}
                    open={
                      selectedCarCompany &&
                      selectedCarCompany.id !== "" &&
                      openCarModel
                    }
                    onClose={() => {
                      setOpenCarModel(false);
                      setOpenCarManufactured(true);
                    }}
                    onOpen={() => {
                      setOpenCarModel(!openCarModel);
                      setOpenCarManufactured(false);
                      setOpenCarVersion(false);
                    }}
                  />
                </Grid>

                <Grid item md={5.5} xs={12}>
                  <AutoCompleteInput
                    label={t("form.selection.manufacture-year.title")}
                    listOption={listManufactured}
                    onChange={setSelectedManufactured}
                    value={selectedManufactured}
                    disabled={
                      selectedModel && selectedModel.id !== "" ? false : true
                    }
                    placeholder={t(
                      "form.selection.manufacture-year.placeholder"
                    )}
                    open={
                      selectedModel &&
                      selectedModel.id !== "" &&
                      openCarManufactured
                    }
                    onClose={() => {
                      setOpenCarManufactured(false);
                      setOpenCarVersion(true);
                    }}
                    onOpen={() => {
                      setOpenCarManufactured(!openCarManufactured);
                      setOpenCarVersion(false);
                    }}
                  />
                </Grid>

                <Grid item md={5.5} xs={12}>
                  <AutoCompleteInput
                    label={t("form.selection.version.title")}
                    listOption={listVersion}
                    onChange={setSelectedVersion}
                    value={selectedVersion}
                    disabled={
                      selectedManufactured && selectedManufactured.id !== ""
                        ? false
                        : true
                    }
                    placeholder={t("form.selection.version.placeholder")}
                    open={
                      selectedManufactured &&
                      selectedManufactured.id !== "" &&
                      openCarVersion
                    }
                    onClose={() => {
                      setOpenCarVersion(false);
                    }}
                    onOpen={() => {
                      setOpenCarVersion(!openCarVersion);
                    }}
                  />
                </Grid>

                <Button
                  style={{
                    width: "44%",
                    height: "50px",
                    background: `${isValidate ? "#5768FF" : "#f0f0f0"}`,
                    borderRadius: "12px",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "20px",
                    textAlign: "center",
                    letterSpacing: "0.005em",
                    color: `${isValidate ? "#ffffff" : "#bdbdbd"}`,
                    textTransform: "unset",
                    wordBreak: "keep-all",
                    display: "block",
                    margin: "0 auto",
                  }}
                  disabled={!isValidate}
                  type="submit"
                >
                  {t("form.button.submit")}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </CoverBox>
  );
};

export default memo(FormAICycle);
