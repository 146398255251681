import React from "react";

const PasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.25 8.25V8.75H13.75H15C15.3255 8.75 15.6283 8.86683 15.8441 9.06099C16.0581 9.25358 16.1667 9.5028 16.1667 9.75V16.5C16.1667 16.7472 16.0581 16.9964 15.8441 17.189C15.6283 17.3832 15.3255 17.5 15 17.5H5.00004C4.67461 17.5 4.37174 17.3832 4.15601 17.189C3.94202 16.9964 3.83337 16.7472 3.83337 16.5V9.75C3.83337 9.5028 3.94202 9.25358 4.15601 9.06099C4.37174 8.86683 4.67461 8.75 5.00004 8.75H6.25004H6.75004V8.25V6.375C6.75004 5.63052 7.07818 4.90439 7.68287 4.36016C8.2893 3.81437 9.12208 3.5 10 3.5C10.878 3.5 11.7108 3.81437 12.3172 4.36016C12.9219 4.90439 13.25 5.63052 13.25 6.375V8.25ZM12.0834 8.75H12.5834V8.25V6.375C12.5834 5.72709 12.2969 5.11787 11.8077 4.67753C11.3201 4.23875 10.6692 4 10 4C9.33091 4 8.67995 4.23875 8.19242 4.67753C7.70315 5.11787 7.41671 5.72709 7.41671 6.375V8.25V8.75H7.91671H12.0834ZM10.9238 15.152C11.1786 14.9226 11.3334 14.5995 11.3334 14.25V12C11.3334 11.6505 11.1786 11.3274 10.9238 11.098C10.6707 10.8702 10.3377 10.75 10 10.75C9.66243 10.75 9.32941 10.8702 9.0763 11.098C8.82145 11.3274 8.66671 11.6505 8.66671 12V14.25C8.66671 14.5995 8.82145 14.9226 9.0763 15.152C9.32941 15.3798 9.66243 15.5 10 15.5C10.3377 15.5 10.6707 15.3798 10.9238 15.152Z"
        fill="#6B7380"
        stroke="#6B7380"
      />
    </svg>
  );
};

export default PasswordIcon;
