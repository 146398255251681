//@ts-nocheck
import { useContext } from "react";
import { Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { BannerProps } from "Components/Banner";
import { ResultCarPrice } from "Components/MainBody";
import CarLoadingPrice from "assets/Images/carLoadingPrice.png";
import CarResult from "assets/Images/carResult.png";
import CarGif from "assets/Images/dinhgia.gif";
import CarError from "assets/Images/error-evaluate.png";
import { isEmpty, isNull } from "lodash";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import UserContext from "userContext";

export type TextPriceInfoProps = {
  isTabletOrMobile: boolean;
};

const SideImageSurround = styled.div<BannerProps>`
  background: #ffffff;
  display: grid;
  align-items: center;
  box-shadow: 0px 4px 25px rgba(102, 105, 133, 0.2);
  border-radius: 24px;
  overflow: auto;
  ${(props) =>
    props.isTabletOrMobile ? "max-width: 100%" : "max-width: 720px;"}
  max-height: 625px;
  /* padding: calc(10px + 2%); */
  /* text-align:center; */
  padding: 40px;
`;

const TextSpanInfoPrice = styled.span<TextPriceInfoProps>`
  font-family: "San Francisco Text";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.isTabletOrMobile ? "14px" : "20px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "28px")};
  color: #1f2738;
`;

const TextSpanInfoListedPrice = styled.span<TextPriceInfoProps>`
  font-family: "San Francisco Text";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.isTabletOrMobile ? "12px" : "18px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "28px")};
  color: #1f2738;
`;

const TextSpanPrice = styled.span<TextPriceInfoProps>`
  font-family: "San Francisco Text";
  font-weight: 700;
  font-size: ${(props) => (props.isTabletOrMobile ? "16px" : "24px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "32px")};
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 14px;
`;

const TextSpanListedPrice = styled.span<TextPriceInfoProps>`
  font-family: "San Francisco Text";
  font-weight: 600;
  font-size: ${(props) => (props.isTabletOrMobile ? "10px" : "18px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "32px")};
  display: flex;
  align-items: center;
  color: #000000;
`;

const TextSpanPricePin = styled.span<TextPriceInfoProps>`
  font-family: "San Francisco Text";
  font-weight: 600;
  font-size: ${(props) => (props.isTabletOrMobile ? "8px" : "16px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "32px")};
  display: flex;
  align-items: center;
  color: #000000;
`;

const TextSpanListedPricePin = styled.span<TextPriceInfoProps>`
  white-space: nowrap;
  font-family: "San Francisco Text";
  font-weight: 500;
  font-size: ${(props) => (props.isTabletOrMobile ? "10px" : "16px")};
  line-height: ${(props) => (props.isTabletOrMobile ? "20px" : "32px")};
  display: flex;
  align-items: center;
  color: #000000;
`;

const TextOnLoadingPrice = styled.span`
  font-family: "San Francisco Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1f2738;
  text-align: center;
  padding-top: 20px;
`;

const CoveringBox = styled.div<BannerProps>`
  position: relative;
  ${(props) => (props.isTabletOrMobile ? "top: 10%;" : "top: 10%;")}
  ${(props) =>
    props.isTabletOrMobile ? "padding-left: 0px;" : "padding-left: 45px;"}
`;

function isNullOrEmpty(value: any): boolean {
  if (typeof value === "number" && value > 0) {
    return false;
  }
  return isNull(value) || isEmpty(value);
}

export function numberWithCommas(x: number | null) {
  if (isNull(x)) return "0";
  return (
    x
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "đ"
  );
}

const formatString = (val: string | null, match: boolean) => {
  if (val && val.length > 14 && match) {
    return val.slice(0, 13) + "...";
  }
  return val;
};

function areAllPropertiesNullOrEmpty(carObject: ResultCarPrice): boolean {
  if (isNullOrEmpty(carObject)) return true;
  for (const key in carObject) {
    if (
      Object.prototype.hasOwnProperty.call(carObject, key) &&
      ![
        "carCompany",
        "carModel",
        "carVersion",
        "isWarning",
        "year",
        "additions",
      ].includes(key)
    ) {
      const value = carObject[key as keyof ResultCarPrice];
      if (!isNullOrEmpty(value)) {
        return false;
      }
    }
  }
  return true;
}

const SideBanner = ({
  carPriceValue,
  isLoadCarPrice,
  error,
}: {
  carPriceValue: ResultCarPrice;
  isLoadCarPrice: boolean;
  error: boolean;
}) => {
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const matches = useMediaQuery("(min-width:1535px)");
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const jsondata = window._env_;
  const { userHidden } = jsondata;

  return (
    <CoveringBox isTabletOrMobile={isTabletOrMobile}>
      <SideImageSurround isTabletOrMobile={isTabletOrMobile}>
        {error ? (
          <>
            <TextSpanPrice isTabletOrMobile={isTabletOrMobile}>
              {t("sidebar.title.error-1")}
              <br />
              {t("sidebar.title.error-2")}
            </TextSpanPrice>
            <img
              src={CarError}
              alt="imageDecoration"
              style={{ display: "block", marginTop: 10 }}
              width={"50%"}
            />
          </>
        ) : null}

        {areAllPropertiesNullOrEmpty(carPriceValue) &&
        !error &&
        !isLoadCarPrice ? (
          <>
            {carPriceValue !== undefined ? (
              <>
                <TextSpanPrice isTabletOrMobile={isTabletOrMobile}>
                  {t("sidebar.title.error-1")}
                  <br />
                  {t("sidebar.title.error-2")}
                </TextSpanPrice>
                <img
                  src={CarError}
                  alt="imageDecoration"
                  style={{ display: "block", marginTop: 10 }}
                  width={"50%"}
                />
              </>
            ) : (
              <img
                src={CarLoadingPrice}
                alt="imageDecoration"
                style={{ display: "block", margin: "auto" }}
                width={"100%"}
              />
            )}
          </>
        ) : (
          <>
            {carPriceValue?.carCompany ? (
              <>
                <TextSpanInfoPrice isTabletOrMobile={isTabletOrMobile}>
                  {t("sidebar.title.main")}
                </TextSpanInfoPrice>
                <TextSpanPrice isTabletOrMobile={isTabletOrMobile}>
                  {carPriceValue?.carValue
                    ? numberWithCommas(carPriceValue.carValue)
                    : "Giá trị xe đang được cập nhật"}
                </TextSpanPrice>
                {userHidden !== user.primaryUname && (
                  <TextSpanInfoPrice isTabletOrMobile={isTabletOrMobile}>
                    {t("sidebar.title.submain-2")}
                  </TextSpanInfoPrice>
                )}
                {userHidden !== user.primaryUname && (
                  <TextSpanPrice isTabletOrMobile={isTabletOrMobile}>
                    {carPriceValue?.minPrice && carPriceValue?.maxPrice
                      ? numberWithCommas(carPriceValue?.minPrice) +
                        "-" +
                        numberWithCommas(carPriceValue?.maxPrice)
                      : "Khoảng giá cấp đơn đang được cập nhật"}
                  </TextSpanPrice>
                )}
              </>
            ) : null}
            {isNullOrEmpty(carPriceValue?.additions) ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexDirection: "column",
                  // mt: 2,
                }}
              >
                {carPriceValue?.listedPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <TextSpanInfoListedPrice
                      isTabletOrMobile={isTabletOrMobile}
                    >
                      {t("sidebar.title.submain-1.title")}:{" "}
                    </TextSpanInfoListedPrice>
                    <TextSpanListedPrice isTabletOrMobile={isTabletOrMobile}>
                      {numberWithCommas(carPriceValue.listedPrice)}
                    </TextSpanListedPrice>
                  </Box>
                ) : null}
                {carPriceValue?.minListedPrice &&
                carPriceValue?.maxListedPrice &&
                userHidden !== user.primaryUname ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <TextSpanInfoListedPrice
                      isTabletOrMobile={isTabletOrMobile}
                    >
                      {t("sidebar.title.submain-1.title-2")}:{" "}
                    </TextSpanInfoListedPrice>
                    <TextSpanListedPrice isTabletOrMobile={isTabletOrMobile}>
                      {numberWithCommas(carPriceValue.minListedPrice) +
                        "-" +
                        numberWithCommas(carPriceValue.maxListedPrice)}
                    </TextSpanListedPrice>
                  </Box>
                ) : null}
                {isNullOrEmpty(carPriceValue?.hanoiOnRoadPrice) &&
                isNullOrEmpty(carPriceValue?.hcmOnRoadPrice) &&
                isNullOrEmpty(carPriceValue?.generalOnRoadPrice) ? null : (
                  <TextSpanInfoListedPrice isTabletOrMobile={isTabletOrMobile}>
                    {t("sidebar.title.submain-3.title")}:{" "}
                  </TextSpanInfoListedPrice>
                )}
                {carPriceValue?.hanoiOnRoadPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <TextSpanListedPricePin isTabletOrMobile={isTabletOrMobile}>
                      &bull; {t("sidebar.title.submain-3.ha-noi")}:{" "}
                    </TextSpanListedPricePin>
                    <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                      {numberWithCommas(carPriceValue.hanoiOnRoadPrice)}
                    </TextSpanPricePin>
                  </Box>
                ) : null}
                {carPriceValue?.hcmOnRoadPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <TextSpanListedPricePin isTabletOrMobile={isTabletOrMobile}>
                      &bull; {t("sidebar.title.submain-3.ho-chi-minh")}:{" "}
                    </TextSpanListedPricePin>
                    <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                      {numberWithCommas(carPriceValue.hcmOnRoadPrice)}
                    </TextSpanPricePin>
                  </Box>
                ) : null}
                {carPriceValue?.generalOnRoadPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <TextSpanListedPricePin isTabletOrMobile={isTabletOrMobile}>
                      &bull; {t("sidebar.title.submain-3.other")}:{" "}
                    </TextSpanListedPricePin>
                    <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                      {numberWithCommas(carPriceValue.generalOnRoadPrice)}
                    </TextSpanPricePin>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Grid container marginTop={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <TextSpanListedPricePin isTabletOrMobile={isTabletOrMobile}>
                      {t("sidebar.title.submain-1.title")}:{" "}
                    </TextSpanListedPricePin>
                    {carPriceValue.additions.map((addition, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          <TextSpanListedPricePin
                            isTabletOrMobile={isTabletOrMobile}
                          >
                            &bull; {addition.optionName}:{" "}
                          </TextSpanListedPricePin>
                          <TextSpanListedPrice
                            isTabletOrMobile={isTabletOrMobile}
                          >
                            {addition.listedPrice
                              ? numberWithCommas(addition.listedPrice)
                              : "Đang cập nhật"}
                          </TextSpanListedPrice>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <TextSpanListedPricePin isTabletOrMobile={isTabletOrMobile}>
                      {t("sidebar.title.submain-1.title-2")}:{" "}
                    </TextSpanListedPricePin>
                    {carPriceValue.additions.map((addition, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          <TextSpanListedPricePin
                            isTabletOrMobile={isTabletOrMobile}
                          >
                            &bull; {addition.optionName}:{" "}
                          </TextSpanListedPricePin>
                          <TextSpanListedPrice
                            isTabletOrMobile={isTabletOrMobile}
                          >
                            {addition.listedPrice
                              ? `${numberWithCommas(
                                  addition.minListedPrice
                                )}-${numberWithCommas(addition.maxListedPrice)}`
                              : "Đang cập nhật"}
                          </TextSpanListedPrice>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Grid container>
                  {carPriceValue.additions.map((addition, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={6}
                      key={index}
                    >
                      <TextSpanListedPricePin
                        isTabletOrMobile={isTabletOrMobile}
                      >
                        {t("sidebar.title.submain-3.title")}-
                        {
                          <Tooltip title={addition.optionName} arrow>
                            <span>
                              {formatString(addition.optionName, matches)}
                            </span>
                          </Tooltip>
                        }
                        :
                      </TextSpanListedPricePin>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                        }}
                      >
                        <TextSpanListedPricePin
                          isTabletOrMobile={isTabletOrMobile}
                        >
                          &bull; {t("sidebar.title.submain-3.ha-noi")}:{" "}
                        </TextSpanListedPricePin>
                        <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                          {addition.hanoiOnRoadPrice
                            ? numberWithCommas(addition.hanoiOnRoadPrice)
                            : "Đang cập nhật"}
                        </TextSpanPricePin>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                        }}
                      >
                        <TextSpanListedPricePin
                          isTabletOrMobile={isTabletOrMobile}
                        >
                          &bull; {t("sidebar.title.submain-3.ho-chi-minh")}:{" "}
                        </TextSpanListedPricePin>
                        <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                          {addition.hcmOnRoadPrice
                            ? numberWithCommas(addition.hcmOnRoadPrice)
                            : "Đang cập nhật"}
                        </TextSpanPricePin>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                        }}
                      >
                        <TextSpanListedPricePin
                          isTabletOrMobile={isTabletOrMobile}
                        >
                          &bull; {t("sidebar.title.submain-3.other")}:{" "}
                        </TextSpanListedPricePin>
                        <TextSpanPricePin isTabletOrMobile={isTabletOrMobile}>
                          {addition.generalOnRoadPrice
                            ? numberWithCommas(addition.generalOnRoadPrice)
                            : "Đang cập nhật"}
                        </TextSpanPricePin>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <Divider style={{ margin: "10px 0px" }} />
          </>
        )}

        {isLoadCarPrice ? (
          <>
            <img
              src={CarGif}
              alt="gif"
              style={{ display: "block", width: "180px" }}
              width={"100%"}
            />
            <TextOnLoadingPrice>
              {t("sidebar.title.loading")}
            </TextOnLoadingPrice>
          </>
        ) : null}

        {!areAllPropertiesNullOrEmpty(carPriceValue) &&
        !error &&
        !isLoadCarPrice ? (
          <>
            {carPriceValue ? (
              <img
                src={CarResult}
                alt="imageDecoration"
                style={{ display: "block", width: "180px" }}
                width={"100%"}
              />
            ) : (
              <img
                src={CarLoadingPrice}
                alt="imageDecoration"
                style={{ display: "block", margin: "auto" }}
                width={"100%"}
              />
            )}
          </>
        ) : null}
      </SideImageSurround>
    </CoveringBox>
  );
};

export default SideBanner;
