import { createContext, Dispatch, SetStateAction } from "react";

export interface User {
  firstName: string;
  lastName: string;
  ocrEnable: boolean;
}

interface UserContextProps {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {},
});

export default UserContext;
