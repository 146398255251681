import React from "react";

export default function IconUptrend() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.55566 10.8892L4.79655 7.64831C4.96989 7.47497 5.25166 7.47497 5.425 7.64831L8.35211 10.5754C8.52544 10.7487 8.80722 10.7487 8.98055 10.5754L14.4437 5.1123"
        stroke="#31C48D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5.11133H14.4444V9.55577"
        stroke="#31C48D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
